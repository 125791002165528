export const isDebug = document.URL.match("/debug/") !== null;
export const isDemo = document.URL.match("/demo/") !== null;

export const home_url = (
	isDebug
		? "https://life-literacy.net/debug"
		: isDemo
			? "https://life-literacy.net/demo"
			: "https://life-literacy.net"
);

export const template_directory_url = `${home_url}/wp-content/themes/sugo`;
