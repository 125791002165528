import { course } from "../model/course";
import { Cell, CellRef, Direction } from "../model/models";

const INIT_POS = { x: 1448, y: 1779 };
const CELL_SIZE = { width: 83, height: 82, shift: 0 };
const BOARD_ROTATE = 34.8 * (Math.PI / 180);
const BOARD_SIZE = { width: 2700, height: 2025 };

export class Board {
    /** DOMの領域 */
    readonly area: HTMLElement;
    private _position: [number, number];

    isShrink = false;

    get position(): [number, number] {
        return this._position;
    }

    set position(value: [number, number]) {
        const width = document.body.clientWidth;
        const height = document.body.clientHeight;
        this._position = value;
        if (this.isShrink) {
            this.area.style.left = `${value[0] / 2 + width / 4}px`;
            this.area.style.top = `${value[1] / 2 + height / 4}px`;
        } else {
            this.area.style.left = `${value[0]}px`;
            this.area.style.top = `${value[1]}px`;
        }
    }

    /**
     * ボードを作成する。
     * @param area DOMの領域
     */
    constructor(area: HTMLElement) {
        this.area = area;
        this._position = [0, 0];
        this._render();
        this._setup_drag();
    }

    /**
     * 位置からセルを取得する。
     * @param ref セルの位置情報
     * @returns 取得したセル
     */
    trace(ref: CellRef): Cell {
        const result = this._trace(course, [...ref]);
        if ("direction" in result) {
            return result;
        } else {
            throw new Error("Cell not found.");
        }
    }

    /**
     * 縮小の切り替え
     * @returns is enabled?
     */
    shrink(): boolean {
        this.isShrink = this.area.classList.toggle("shrink");
        this.position = this._position;
        return this.isShrink;
    }

    /**
     * 指定したセルが画面中央になるようにボードを移動。
     * @param cell 中央に表示するセル
     */
    focus(cell: Cell): void {
        // セルのサイズ
        const cw = CELL_SIZE.width;
        const ch = CELL_SIZE.height;
        // 目標座標
        const cx = cell.x!;
        const cy = cell.y!;
        // ボードのサイズ
        const bw = document.body.clientWidth;
        const bh = document.body.clientHeight;
        // 目標地点
        const tx = cx + cw / 2;
        const ty = cy + ch / 2;
        // 最終的な座標
        const nx = bw / 2 - tx;
        const ny = bh / 2 - ty;
        this.position = [nx, ny];
    }

    private _setup_drag(): void {
        let status = {
            ix: 0,
            iy: 0,
            px: 0,
            py: 0,
            drag: false
        };

        const _is_touch_event = (event: MouseEvent | TouchEvent): event is TouchEvent => (event as TouchEvent).touches !== undefined;

        const _drag_start = (event: MouseEvent | TouchEvent): void => {
            const position = _is_touch_event(event) ? event.touches[0] : event;
            event.preventDefault();
            this.area.classList.add("drag");
            status = {
                ix: this.position[0],
                iy: this.position[1],
                px: position.pageX,
                py: position.pageY,
                drag: true,
            };
        };

        const _drag_move = (event: MouseEvent | TouchEvent): void => {
            const position = _is_touch_event(event) ? event.touches[0] : event;
            const padding = 800;
            event.preventDefault();
            if (status.drag) {
                let nx = status.ix + position.pageX - status.px;
                let ny = status.iy + position.pageY - status.py;
                const px = (BOARD_SIZE.width - document.body.clientWidth) * -1 - padding;
                const py = (BOARD_SIZE.height - document.body.clientHeight) * -1 - padding;
                // 画面外に行けないようにする
                if (nx > padding) nx = padding;
                else if (nx < px) nx = px;
                if (ny > padding) ny = padding;
                else if (ny < py) ny = py;
                this.position = [nx, ny];
            }
        };

        const _drag_end = (event: MouseEvent | TouchEvent): void => {
            event.preventDefault();
            this.area.classList.remove("drag");
            if (status.drag) status.drag = false;
        };

        this.area.addEventListener("mousedown", _drag_start);
        this.area.addEventListener("mousemove", _drag_move);
        this.area.addEventListener("mouseup", _drag_end);
        this.area.addEventListener("mouseleave", _drag_end);

        this.area.addEventListener("touchstart", _drag_start);
        this.area.addEventListener("touchmove", _drag_move);
        this.area.addEventListener("touchend", _drag_end);
        this.area.addEventListener("touchcancel", _drag_end);
    }

    private _trace(route: any, array: (string | number)[]): any {
        return !array || array.length === 0
            ? route
            : this._trace(route[array.shift()!], array);
    }

    private _render_cells(cells: Cell[], data: { x: number, y: number, }, tracer: (string | number)[]): void {
        const area = document.getElementById("cells");
        let prev: Cell | undefined = undefined;
        // 
        const fragment = new DocumentFragment();
        cells.forEach((cell: Cell, index: number) => {
            if (cell.x) data.x = cell.x;
            if (cell.y) data.y = cell.y;
            // 
            switch (cell.direction) {
                case Direction.Up:
                    if (!cell.x) data.x += CELL_SIZE.shift;
                    if (!cell.x) data.x -= CELL_SIZE.width * Math.cos(BOARD_ROTATE);
                    if (!cell.y) data.y -= CELL_SIZE.height * Math.sin(BOARD_ROTATE);
                    break;
                case Direction.Down:
                    if (!cell.x) data.x -= CELL_SIZE.shift;
                    if (!cell.x) data.x += CELL_SIZE.width * Math.cos(BOARD_ROTATE);
                    if (!cell.y) data.y += CELL_SIZE.height * Math.sin(BOARD_ROTATE);
                    break;
                case Direction.Left:
                    if (!cell.x) data.x -= CELL_SIZE.width * Math.cos(BOARD_ROTATE);
                    if (!cell.y) data.y += CELL_SIZE.height * Math.sin(BOARD_ROTATE);
                    break;
                case Direction.Right:
                    if (!cell.x) data.x += CELL_SIZE.width * Math.cos(BOARD_ROTATE);
                    if (!cell.y) data.y -= CELL_SIZE.height * Math.sin(BOARD_ROTATE);
                    break;
                default:
                    break;
            }
            // 
            cell.x = data.x;
            cell.y = data.y;

            //角度の調整
            cell.x = data.x;
            cell.y = data.y;

            // @ts-ignore TS-2321
            cell.ref = [...tracer, index];
            const element = document.createElement("div");
            // element.innerText = cell.name;
            element.classList.add("item", cell.direction, ...cell.events.map(ev => ev.kind));
            element.setAttribute("data-evid", cell.evid);
            element.style.left = `${cell.x}px`;
            element.style.top = `${cell.y}px`;
            element.style.width = `${CELL_SIZE.width}px`;
            element.style.height = `${CELL_SIZE.height}px`;
            // element.addEventListener("click", () => {
            //     const message = cell.events.map(event => event.message).join(" and ");
            //     repo.dialog.select(div`<p>${cell.name}</p><p>${message}</p>`, [{ text: "OK!" }]);
            // });
            fragment.appendChild(element);
            // 要素を書き換え
            cell.element = element;
            // 前のコマの参照を書き換え
            if (prev != undefined) {
                prev.next = cell;
            }
            // 前のセルを参照
            cell.prev = prev;
            // 参照を書き換え
            prev = cell;
        });
        // 断片を追加
        area?.appendChild(fragment);
    }

    /**
     * セクションをレンダリング
     */
    private _render_section(name: "Main"): void {
        // 前回のセクション
        let prev: Cell[][] = [];
        // 前回の最終座標
        let pos1 = { ...INIT_POS };
        let pos2 = { ...INIT_POS };
        // コース内容をイテレーション
        course[name].forEach((section: Cell[][], index: number) => {
            // 前回のセクションの末尾を
            prev.forEach(item => item[item.length - 1].next = section);
            prev = section.length == 0 ? prev : section;
            // 上ルートのレンダリング
            this._render_cells(section[0], pos1, [name, index, 0]);
            // 下ルートの存在確認
            if (!section[1]) {
                // 無い場合は上ルートに合わせる。
                pos2 = { ...pos1 };
            } else {
                // 下ルートのレンダリング
                this._render_cells(section[1], pos2, [name, index, 1]);
            }
        });
    }

    /**
     * ボードの生成
     */
    private _render(): void {
        this._render_section("Main");
        this.focus(this.trace(["Main", 0, 0, 0]));
    }
}
