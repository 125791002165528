import { Player } from "./model/player";
import { Audio } from "./view/audio";
import { Board } from "./view/board";
import { Chat } from "./view/chat";
import { Dialog } from "./view/dialog";
import { Dice } from "./view/dice";
import { Log } from "./view/log";
import { Message } from "./view/message";
import { Status } from "./view/status";

export class Repo {
    debug: boolean;
    init: boolean = false;

    // turn data
    turn: number = 0;
    move: number = 0;

    // partially goal
    goal: number = 0;

    // Players
    current!: Player;
    players: Player[];

    // Others
    ev_112_passes: number = 0;
    status_lock: boolean = false;
    once_stop_log: string = "";

    // DOM Elements
    readonly board: Board;
    readonly bgm: Audio;
    readonly chat: Chat;
    readonly dialog: Dialog;
    readonly dice: Dice;
    readonly log: Log;
    readonly message: Message;
    readonly se: Audio;
    readonly status: Status;

    constructor() {
        this.debug = this.isDebug();
        //if (this.debug) alert("デバッグモードが有効です。\nこのモードではサイコロを振った後に進みたいマス目の数を指定して移動します。");
        this.players = [];
        this.board = new Board(document.getElementById("board")!);
        this.bgm = new Audio(document.getElementById("bgm") as HTMLMediaElement);
        this.chat = new Chat(document.getElementById("chat-input")! as HTMLInputElement, document.getElementById("chat-button")!);
        this.dialog = new Dialog(document.getElementById("dialog")!);
        this.dice = new Dice(document.getElementById("dice")!, 1, 6);
        this.log = new Log(document.getElementById("log")!);
        this.message = new Message(document.getElementById("message")!);
        this.se = new Audio(document.getElementById("se") as HTMLMediaElement);
        this.status = new Status(document.getElementById("status")!);
        this.ev_112_passes = 0;
    }

    private isDebug() {
        const param = new URLSearchParams(location.search);
        return param.has("debug");
    }

    getPlayerById(id: number | string) {
        return this.players.find(player => player.id == id);
    }

}

export const repo = new Repo();
