import { template_directory_url } from "../constant";
import { repo } from "../repo";
import { sleep } from "../util";

export class Dialog {
    readonly area: HTMLElement;

    constructor(area: HTMLElement) {
        this.area = area;
    }

    async event_dice(message: HTMLElement, duration: number, classname?: string[], dice_result?: number): Promise<void> {
        this.area.innerHTML = "";
        return new Promise<void>(
            async (res) => {

                repo.se.play(`${template_directory_url}/assets/audio/se_dice.mp3`);
                message.classList.add("message");
                const buttons = document.createElement("div");
                buttons.classList.add("buttons");

                // 中身を生成
                const content = document.createElement("div");
                content.classList.add("content");

                //クラス付与
                if (classname) {
                    content.classList.add(...classname);
                }

                //見出し
                const h2 = document.createElement("h2");
                h2.innerText = "サイコロの出目";
                content.appendChild(h2);

                // サイコロの表示
                const dialog_dice_result = document.createElement("img");
                dialog_dice_result.classList.add("dialog_dice_result");
                dialog_dice_result.setAttribute("src", `${template_directory_url}/assets/img/dice_0${dice_result}.webp`);
                content.appendChild(dialog_dice_result);

                //結果に対応したメッセージ
                content.appendChild(message);

                content.addEventListener("click", ev => ev.stopPropagation());

                // 中身をセット
                this.area.appendChild(content);
                this.area.classList.add("show");
                await sleep(duration);
                this.close();
                res();
            }
        );
    }

    async select<T>(message: HTMLElement, actions: DialogItem<T>[], image?: string, classname?: string[], voice?: string[]): Promise<T> {
        this.area.innerHTML = "";
        return new Promise<T>(
            (res) => {
                //repo.se.play(`${template_directory_url}/assets/audio/se_dialog.mp3`);
                message.classList.add("message");
                const buttons = document.createElement("div");
                buttons.classList.add("buttons");
                actions.forEach(item => {
                    const element = document.createElement("div");
                    element.innerHTML = item.text;
                    element.addEventListener("click", () => {
                        res(item.value);
                        this.close();
                    });
                    if (item.highlight) element.classList.add("highlight");
                    buttons.appendChild(element);
                });
                // 中身を生成
                const content = document.createElement("div");
                content.classList.add("content");

                //クラス付与
                if (classname) {
                    content.classList.add(...classname);
                }

                if (image) {
                    const img = document.createElement("img");
                    img.src = image;
                    content.appendChild(img);
                }

                // 上部バーの生成
                const dialog_header = document.createElement("div");
                dialog_header.classList.add("dialog_header");

                if (voice) {	//音声データがあればボタン追加追加
                    const dialog_voice = document.createElement("button");

                    dialog_voice.classList.add("voice_play");
                    dialog_voice.addEventListener("click", function () {
                        repo.se.play(`${template_directory_url}/assets/audio/${voice[0]}.mp3`);
                    });
                    dialog_header.appendChild(dialog_voice);

                }

                content.appendChild(dialog_header);

                // 上部アイコンの生成
                const dialog_header_icons = document.createElement("div");
                dialog_header_icons.classList.add("dialog_header_icons");
                content.appendChild(dialog_header_icons);

                message.appendChild(buttons);
                content.appendChild(message);
                //content.appendChild(buttons);
                content.addEventListener("click", ev => ev.stopPropagation());
                // 中身をセット
                this.area.appendChild(content);
                this.area.classList.add("show");
            }
        );
    }

    async notify(message: HTMLElement, duration: number, image?: string, classname?: string[], voice?: string[]): Promise<void> {
        //durationは廃止し、閉じるボタンで閉じるように変更
        this.area.innerHTML = "";
        return new Promise<void>(
            async (res) => {
                //repo.se.play(`${template_directory_url}/assets/audio/se_dialog.mp3`);
                message.classList.add("message");
                const buttons = document.createElement("div");
                buttons.classList.add("buttons");
                const button = document.createElement("div");
                button.innerHTML = "閉じる";
                button.addEventListener("click", () => {
                    res();
                    this.close();
                });
                buttons.appendChild(button);

                // 中身を生成
                const content = document.createElement("div");
                content.classList.add("content");

                //クラス付与
                if (classname) {
                    content.classList.add(...classname);
                }

                if (image) {
                    const img = document.createElement("img");
                    img.src = image;
                    content.appendChild(img);
                }

                // 上部バーの生成
                const dialog_header = document.createElement("div");
                dialog_header.classList.add("dialog_header");

                if (voice) {	//音声データがあればボタン追加追加
                    const dialog_voice = document.createElement("button");

                    dialog_voice.classList.add("voice_play");
                    dialog_voice.addEventListener("click", function () {
                        repo.se.play(`${template_directory_url}/assets/audio/${voice[0]}.mp3`);
                    });
                    dialog_header.appendChild(dialog_voice);

                }

                content.appendChild(dialog_header);

                // 上部アイコンの生成
                const dialog_header_icons = document.createElement("div");
                dialog_header_icons.classList.add("dialog_header_icons");
                content.appendChild(dialog_header_icons);

                content.appendChild(message);
                content.appendChild(buttons);
                content.addEventListener("click", ev => ev.stopPropagation());
                // 中身をセット
                this.area.appendChild(content);
                this.area.classList.add("show");
                // await sleep(duration);
                // this.close();
                // res();
            }
        );
    }

    async custom<T>(message: HTMLElement, actions: CustomDialogItem<T>[], image?: string, classname?: string[], voice?: string[]): Promise<T> {
        this.area.innerHTML = "";
        return new Promise<T>(
            (resolve) => {
                //repo.se.play(`${template_directory_url}/assets/audio/se_dialog.mp3`);
                const res = (value: T | PromiseLike<T>) => { resolve(value); this.close(); };
                message.classList.add("message");
                const buttons = document.createElement("div");
                buttons.classList.add("buttons");
                actions.forEach(item => {
                    const element = document.createElement("div");
                    element.innerText = item.text;
                    element.addEventListener("click", () => item.callback(res));
                    if (item.highlight) element.classList.add("highlight");
                    buttons.appendChild(element);
                });
                // 中身を生成
                const content = document.createElement("div");
                content.classList.add("content");
                if (image) {
                    const img = document.createElement("img");
                    img.src = image;
                    content.appendChild(img);
                }

                //クラス付与
                if (classname) {
                    content.classList.add(...classname);
                }

                // 上部バーの生成
                const dialog_header = document.createElement("div");
                dialog_header.classList.add("dialog_header");

                if (voice) {	//音声データがあればボタン追加追加
                    const dialog_voice = document.createElement("button");

                    dialog_voice.classList.add("voice_play");
                    dialog_voice.addEventListener("click", function () {
                        repo.se.play(`${template_directory_url}/assets/audio/${voice[0]}.mp3`);
                    });
                    dialog_header.appendChild(dialog_voice);

                }

                content.appendChild(dialog_header);

                // 上部アイコンの生成
                const dialog_header_icons = document.createElement("div");
                dialog_header_icons.classList.add("dialog_header_icons");
                content.appendChild(dialog_header_icons);

                content.appendChild(message);
                content.appendChild(buttons);
                content.addEventListener("click", ev => ev.stopPropagation());
                // 中身をセット
                this.area.appendChild(content);
                this.area.classList.add("show");
            }
        );
    }

    close() {
        this.area.classList.remove("show");
    }
}

export interface DialogItem<T> {
    text: string;
    value: T;
    highlight?: boolean;
}

export interface CustomDialogItem<T> {
    text: string;
    callback: (callback: (value: T | PromiseLike<T>) => void) => void;
    highlight?: boolean;
}
