export class Message {

    /** DOM */
    private readonly area: HTMLElement;

    constructor(area: HTMLElement) {
        this.area = area;
    }

    update(message: string) {
        this.area.innerText = message;
    }
    updateHTML(message: string) {
        this.area.innerHTML = message;
    }
}
