import { home_url, template_directory_url } from "../constant";
import { postGoal } from "../game";
import { repo } from "../repo";
import { div, sleep } from "../util";
import { PlayLog } from "../view/log";
import { Piece } from "../view/piece";
import { Cell, ChartLog, EventKind } from "./models";

export const INIT_FUND: number = 0;
export const INIT_POPLATION: number = 0;
export const INIT_JOB: number = 0;

export class Player {
	/** プレイヤーの順番 */
	readonly index: number;

	/** プレイヤーのID */
	readonly id: number;

	/** プレイヤーの名前 */
	readonly name: string;

	/** プレイヤーのコマ */
	readonly piece: Piece;

	chara: number;

	/** 現在資金 */
	balance: number;

	/** 各種保険 */
	isKokuho: boolean = false;
	isKenpo: boolean = false;
	isNenkin: boolean = false;
	isMeisai: boolean = false;
	isTainou: boolean = false;

	/*休み回数：これが1以上なら手番をスキップ*/
	RestCount: number = 0;

	/*直近のイベントのダイス結果*/
	last_ev_dice: number;

	/** プレイヤーの職業 */
	job: number;

	/** ゴール判定 */
	isGoaled: boolean = false;

	/** AFK判定 */
	isAfk: boolean = false;

	chart: ChartLog[] = [];

	constructor(index: number, id: number, name: string, piece: Piece, chara: number) {
		this.index = index;
		this.id = id;
		this.name = name;
		this.piece = piece;
		this.chara = chara;
		this.balance = INIT_FUND;
		this.job = INIT_JOB;
	}

	/**
	 * アニメーション待ちを入れつつコマを移動させる。
	 * @param cell 移動先セル
	 * @param duration アニメーション待ち時間
	 */
	private async move(cell: Cell, duration: number) {
		// 初期化済みならフォーカスを合わせる。
		this.piece.move(cell);

		if (repo.init) {
			repo.board.focus(cell);

			//後で消す
			if (repo.debug) {
				duration = 10;
			}
			await sleep(duration);
		}
	}

	private async forward(remain: number, cell: Cell, messages: string[], moves: Cell[]): Promise<[Cell, boolean]> {
		// 残りが0以下ならセルを返す。
		if (remain <= 0) return [cell, false];
		remain--;
		// 次のセルを取得
		const next = cell.next;
		// 次のセルが存在しない、本来この状況が起こらないようにマップデータが作られるはず。
		if (next == undefined) {
			//alert("Error: 次に進むセルのデータがありません.");
			return [cell, false];
		}
		// nextがArrayは起こりえないためエラーとなる。
		if (next instanceof Array) {
			//alert("Error: 次に進むセルのデータが不正です.");
			return [cell, false];
		}
		// セルを移動先に追加
		moves.push(next);
		// 停止系イベントの場合は中断
		if (next.events.some(ev => {
			switch (ev.kind) {
				case EventKind.ForceStop:
					return true;
					break;
				case EventKind.ForceStopP35:
					console.log("job", repo.current.job);
					if (repo.current.job == 2 || repo.current.job == 3) {
						console.log("stop!!!!");
						return true;
					}
					break;
				case EventKind.ForceStopOnce:
					if (document.querySelector(`.passed[data-evid="${next.evid}"]`)) {
						return false;
					} else {
						return true;
					}
				case EventKind.Wait:
					if (next.evid == "112") {
						repo.log.RecoverWait();
					}
					break;
				default:
					return false;
			}
		})) return [next, true];
		// MEMO: ここは別で任せることになる。
		// // コマを次のセルに動かす
		// await this.move(next, 400);
		// // 強制イベントをハンドル
		// const force = await this.handleForceEvent(next, messages);
		// if (force) return next;
		// 再帰で次に任せる、スタックが6以上積まれることは無いはずなので安全
		return this.forward(remain, next, messages, moves);
	}

	private async backward(remain: number, cell: Cell, messages: string[], moves: Cell[]): Promise<[Cell, boolean]> {
		// 残りが0以下ならセルを返す。
		if (remain <= 0) return [cell, false];
		remain--;
		// 次のセルを取得
		const next = cell.prev;
		// 次のセルが存在しない、本来この状況が起こらないようにマップデータが作られるはず。
		if (next == undefined) {
			alert("Error: 次に進むセルのデータがありません.");
			return [cell, false];
		}
		// nextがArrayは起こりえないためエラーとなる。
		if (next instanceof Array) {
			alert("Error: 次に進むセルのデータが不正です.");
			console.log(next);
			return [cell, false];
		} else {
			console.log(next);
		}
		// セルを移動先に追加
		moves.push(next);
		// 停止系イベントの場合は中断
		if (next.events.some(ev => {
			switch (ev.kind) {
				case EventKind.ForceStop:
					return true;
				case EventKind.ForceStopOnce:
					if (document.querySelector(`.passed[data-evid="${cell.evid}"]`)) {
						return false;
					} else {
						return true;
					}
				default:
					return false;
			}
		})) return [next, true];
		// MEMO: ここは別で任せることになる。
		// // コマを次のセルに動かす
		// await this.move(next, 400);
		// // 強制イベントをハンドル
		// const force = await this.handleForceEvent(next, messages);
		// if (force) return next;
		// 再帰で次に任せる、スタックが6以上積まれることは無いはずなので安全
		return this.backward(remain, next, messages, moves);
	}

	// 通常イベントのハンドル
	async handleNormalEvent(current: Cell, messages: string[] = [], option: number | "wait" | "none" = "none"): Promise<[Cell, number | "wait" | "none"]> {
		const myevent = this.id == repo.current.id;
		const interact = repo.init;
		// const messages: string[] = [];
		// console.log("handleNormalEvent", current, option)
		console.log({ myevent });

		for (const [index, ev] of (current.events ?? []).entries()) {
			if (typeof ev.value == "number") {
				let value = ev.value;
				let result = 0;
				let btn_label;
				let items;
				let start: Cell;
				// イベントの値が数値の場合

				switch (ev.kind) {
					case EventKind.None:
						//この時点で先行してcommitを送る
						if (interact && myevent) {
							console.log("committed");
							const now = Date.now();
							await repo.log.postPlaylog({
								epoch: now,
								player: this.id,
								cellref: current.ref!,
								dice: result,
								option: option,
								commit: true,
							});
						}
						// Push Message
						if (index === 0) messages.push(`何もないマスに停止しました`);
						break;
					case EventKind.Quiz:
						// SEを再生
						// ポップアップを表示
						repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);
						await this.piece.popup();

						let quiz_elem_category = [...ev.evCategory ?? []];
						quiz_elem_category.push(`pl${this.index}`);

						items = [];

						const quiz_voice = [`${ev.voice?.[this.index]}-q${this.index + 1}`];
						const answer_voice = [`${ev.voice?.[this.index]}-a${this.index + 1}`];

						if (ev.quiz_choices) {
							ev.quiz_choices[this.index].forEach((choice, i) => {
								items.push({ text: choice, value: i });
							});
						}

						let quiz_result = false;

						if (interact) {
							if (myevent) {
								// 強制的にコミットして他のプレイヤーにもポップを出す。
								let now = Date.now();
								await repo.log.postPlaylog({
									epoch: now,
									player: this.id,
									cellref: current.ref!,
									dice: result,
									option: "wait",
									commit: true,
									ev_chain: true,
								});

								//クイズ出題
								option = await repo.dialog.select<number>(
									div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
									items,
									current.image,
									quiz_elem_category,
									quiz_voice
								);
								// 正答を送信して待機状態を解消
								now = Date.now();
								await repo.log.postPlaylog({
									epoch: now,
									player: this.id,
									cellref: current.ref!,
									dice: result,
									option: option,	//※ここ以外は更新しません
									commit: true,
								});
								//正答のポップ表示
								items = [{ text: "閉じる", value: true },];
								await repo.dialog.select<boolean>(
									div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.quiz_answer}`,
									items,
									current.image,
									quiz_elem_category,
									answer_voice
								);
							} else {
								//非手番側プレイヤーの処理
								if (option == "wait") {
									await repo.dialog.select<number>(
										div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
										items,
										current.image,
										quiz_elem_category,
										quiz_voice
									);

									repo.message.update("手番プレイヤーの選択を待っています");

									break;
								} else {
									repo.message.update((Number(option) + 1) + "番が選択されました");

									await repo.dialog.notify(
										div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.quiz_answer}`,
										8000,
										current.image,
										quiz_elem_category,
										answer_voice
									);
								}
							}
						}
						//クイズの正誤判定
						switch (this.index) {
							case 0:
								quiz_result = (option == 1) ? true : false;
								break;
							case 1:
								quiz_result = (option == 0) ? true : false;
								break;
							case 2:
								quiz_result = (option == 2) ? true : false;
								break;
							case 3:
								quiz_result = (option == 1) ? true : false;
								break;
						}

						// Push Message
						if (index === 0) messages.push(`クイズの結果：${quiz_result ? "正解（1マス進む）" : "不正解"}`);

						// 移動用関数で指定したマスの数だけ動かす
						if (quiz_result) {
							const moves = [];
							let flag;
							let move_value = 1;

							alert("正解のため1マス進みます。");
							[current, flag] = await this.forward(move_value, this.piece.current, messages, moves);
							for (const item of moves) {
								await this.move(item, repo.init ? 400 : 0);
								//await this.handleForceEvent(item, messages)
							}
							/*
														// 通常イベントの呼び出し→クイズの移動先にイベントはないので不要
														let next;
														[next, option] = await this.handleNormalEvent(current, messages)
														if (interact && myevent) {
															const now = Date.now()
															// 状態を送信
															await repo.log.postPlaylog({
																epoch: now,
																player: this.id,
																cellref: next.ref!,
																dice: move_value,
																option: option,
																commit: false,
															})
														}
							*/
						}
						break;
					case EventKind.Ev61:
					case EventKind.P40:
					case EventKind.P42:
					case EventKind.MoveNormal:
						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							let se = value >= 0 ? "receive" : "lose";
							if (current.evid == "65") {
								se = "lose";
							}
							repo.se.play(`${template_directory_url}/assets/audio/se_${se}.mp3`);
							await this.piece.popup();
						}

						btn_label = ev.btn_label ? ev.btn_label : `閉じる`;
						items = [{ text: btn_label, value: true }];

						let is_chain = ev.chain === undefined ? false : ev.chain;

						//この時点で先行してcommitを送る
						if (interact && myevent) {
							const now = Date.now();
							if (ev.kind == EventKind.P40) {
								if (interact && option == "none") {
									this.last_ev_dice = 1 + Math.floor(Math.random() * 6);
									option = this.last_ev_dice;
								}
								if (myevent) {
									switch (option) {
										case 1:
										case 3:
										case 5:
											if (myevent) {
												this.RestCount = 1;
												await repo.log.PostPlayerStatus(this);
											}
											break;
									}
								}
							}
							if (ev.kind == EventKind.P42 && this.isTainou) {
								this.RestCount = 2;
								await repo.log.PostPlayerStatus(this);
							}

							await repo.log.postPlaylog({
								epoch: now,
								player: this.id,
								cellref: current.ref!,
								dice: result,
								option: option,
								commit: true,
								ev_chain: is_chain,
							});
						}

						if (interact && myevent) await repo.dialog.select<boolean>(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							items,
							current.image,
							ev.evCategory,
							ev.voice
						);
						else if (interact) await repo.dialog.notify(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							8000,
							current.image,
							ev.evCategory,
							ev.voice
						);

						if (ev.kind == EventKind.Ev61 && this.job != 1) {
							value = 0;
							break;
						}

						// Push Message
						if (index === 0) messages.push(`${Math.abs(value)}マス${value >= 0 ? "進む" : "戻る"}`);

						// 移動用関数で指定したマスの数だけ動かす
						const moves = [];
						let flag;

						switch (ev.kind) {
							case EventKind.P40:
								let result_text = "";

								switch (option) {
									case 1:
									case 3:
									case 5:
										if (myevent) {
											this.RestCount = 1;
										}
										value = 0;
										result_text = "会社が労災未加入だった！自分で手続きすることに！<br>１回休みです。";
										break;
									case 2:
									case 4:
									case 6:
										value = 6;
										result_text = "会社が労災に加入していたため安心。6つ進みます。";
										break;
								}
								if (interact) await repo.dialog.event_dice(
									div`<p>${result_text}</p>`,
									5000,
									['common'],
									Number(option)
								);
								if (value > 0) {
									[current, flag] = await this.forward(value, this.piece.current, messages, moves);
									for (const item of moves) {
										await this.move(item, repo.init ? 400 : 0);
										await this.handleForceEvent(item, messages);
									}
								}
								break;
							case EventKind.P42:
								if (this.isTainou) {
									value = 0;
									alert("年金を滞納していたため２回休みです。");
								} else {
									value = 1;
									alert("年金の滞納がないため1つ進みます。");
									[current, flag] = await this.forward(value, this.piece.current, messages, moves);
									for (const item of moves) {
										await this.move(item, repo.init ? 400 : 0);
										await this.handleForceEvent(item, messages);
									}
								}
								break;
							case EventKind.Ev61:
							case EventKind.MoveNormal:
								if (value > 0) {
									[current, flag] = await this.forward(value, this.piece.current, messages, moves);
								} else {
									[current, flag] = await this.backward(Math.abs(value), this.piece.current, messages, moves);
								}
								for (const item of moves) {
									await this.move(item, repo.init ? 400 : 0);
									await this.handleForceEvent(item, messages);
								}
						}
						result = value;

						if (value != 0 && is_chain) {
							// 通常イベントの呼び出し
							if (interact && myevent) {
								let next;
								[next, option] = await this.handleNormalEvent(current, messages);
								const now = Date.now();
								// 状態を送信
								await repo.log.postPlaylog({
									epoch: now,
									player: this.id,
									cellref: next.ref!,
									dice: value,
									option: option,
									commit: false,
								});
								// チャットログに表示
								repo.log.push({
									chat: false,
									epoch: now,
									player: this.id,
									messages: messages,
								});
							}
						}
						break;
					case EventKind.P9:
					case EventKind.P34:
					case EventKind.P35:
					case EventKind.Wait:
					case EventKind.MsgNormal:
						btn_label = ev.btn_label ? ev.btn_label : `閉じる`;
						items = [{ text: btn_label, value: true }];

						repo.status_lock = true;

						// //evidが112のときは専用処理
						if (ev.kind == EventKind.Wait) {
							if (interact && myevent) {
								if (Number(repo.ev_112_passes) + 1 !== repo.players.length) {
									this.RestCount = 100;
									await repo.log.PostPlayerStatus(this);
								}
							}
						}

						let result_msg = "";

						//ダイス結果だけ先に生成するように変更
						if (ev.kind == EventKind.P34 || ev.kind == EventKind.P35) {
							if (interact && option == "none") {
								this.last_ev_dice = 1 + Math.floor(Math.random() * 6);
								console.log("job", this.job);
								if (ev.kind == EventKind.P35 && this.job != 2 && this.job != 3) {
									this.last_ev_dice = 0;
								}
								option = this.last_ev_dice;
							}
							if (ev.kind == EventKind.P34) {
								switch (option) {
									case 1:
									case 2:
									case 3:
										if (myevent) {
											this.job = 2;
											this.isKenpo = false;
											this.isKokuho = true;
											this.RestCount = 1;
										}
										result_msg = "会社が倒産したことで「フリーター」になりました…";
										break;
									case 4:
									case 5:
									case 6:
									default:
										result_msg = "倒産を回避できました。";
										break;
								}
							} else if (ev.kind == EventKind.P35) {
								switch (option) {
									case 1:
									case 2:
										if (myevent) {
											this.RestCount = 1;
											this.isTainou = true;
										}
										result_msg = "何もしなかったため年金滞納になってしまった。<br>１回休みです。";
										break;
									case 3:
									case 4:
										result_msg = "市役所に相談に行き、１回休みを回避しました。";
										break;
									case 5:
									case 6:
										if (myevent) {
											this.job = 1;
											this.isKenpo = true;
											this.isKokuho = false;
										}
										result_msg = "正社員として会社に就職しました。";
										break;
								}
							}
							if (myevent) {
								await repo.log.PostPlayerStatus(this);
							}
						}

						//休み回数が設定されてたら休みを追加
						if (ev.rest && myevent) {
							this.RestCount = Number(this.RestCount) + Number(ev.rest);
							await repo.log.PostPlayerStatus(this);
						}

						//この時点で先行してcommitを送る
						if (interact && myevent) {
							const now = Date.now();

							await repo.log.postPlaylog({
								epoch: now,
								player: this.id,
								cellref: current.ref!,
								dice: result,
								option: option,
								commit: true,
							});
						}

						// MEMO: "passed"クラスが付与されている場合、以降のイベント停止。
						if (document.querySelector(`.passed[data-evid="${current.evid}"]`) !== null) break;

						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							switch (true) {
								case value >= 0:
									repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);
									break;
								case value < 0:
									repo.se.play(`${template_directory_url}/assets/audio/se_lose.mp3`);
									break;
								default:
									break;
							}
							await this.piece.popup();
						}


						if (interact) await repo.dialog.select<boolean>(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							items,
							current.image,
							ev.evCategory,
							ev.voice
						);

						result = value;
						this.balance += result;

						// Push Message
						if (index === 0) messages.push(`Event: ${current.name} ${result > 0 ? "receive" : "lose"} ${Math.round(Math.abs(result)).toLocaleString()} USD`);

						switch (ev.kind) {
							case EventKind.P9:
								if (myevent) {
									this.isKokuho = true;
									this.isNenkin = true;
									await repo.log.PostPlayerStatus(this);
								}
								break;
							case EventKind.P34:
								if (interact) await repo.dialog.event_dice(
									div`<p>${result_msg}</p>`,
									5000,
									['common'],
									Number(option)
								);
								break;
							case EventKind.P35:
								if (option != 0) {
									if (interact) await repo.dialog.event_dice(
										div`<p>${result_msg}</p>`,
										5000,
										['common'],
										Number(option)
									);
								} else {
									alert("会社員のため影響はありません");
								}
								break;
							default:
								console.log(ev.kind);
								break;
						}
						repo.status_lock = false;
						break;
					case EventKind.P8:
						repo.status_lock = true;

						//「自分の手番でない時」で「最新のログのoptionがnone（≒移動マスから来た時）」
						if (interact && !myevent && option == "none") {
							break;

							/*
							//最新ログのoptionがnoneでなくなるまで監視し続ける。
							//noneでなくなったらoptionをその値で上書きする。
							while (true) {
								console.log("loop")
								await sleep(500)
								let check_wait_res = await repo.log.getPlaylog()
								if (!check_wait_res) continue
								let [data] = check_wait_res
								if (!data) continue
								let last_data = data[data.length - 1]
								if (!last_data) continue
								if (last_data.option != "none" && last_data.option !== undefined) {
									option = last_data.option
									console.log("break")
									break
								}
							}
							*/
						}

						//ダイス結果だけ先に生成しつつ、先行してcommitを送る
						if (interact && myevent) {
							this.last_ev_dice = 1 + Math.floor(Math.random() * 6);
							option = this.last_ev_dice;

							const now = Date.now();

							await repo.log.postPlaylog({
								epoch: now,
								player: this.id,
								cellref: current.ref!,
								dice: result,
								option: option,
								commit: true,
							});
						}

						let job_text = "";
						let ev_move = 0;

						if (current.next === undefined) continue;

						start = current.next[0][0];

						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);
							await this.piece.popup();
						}
						//１つ目のメッセージ
						if (interact) await repo.dialog.select(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>
							<h1>卒業、そして就職！</h1>
							<h2>就職したら健康保険に加入します</h2>
							<p>就職すると自分で保険料を払い、健康保険組合に加入します。
							日本ではすべての人が、職業別に公的な医療保険に入る決まりだからです。
							これを「国民皆保険」と言います。種別はおおむね、つぎの通り。</p>
							<img src='${template_directory_url}/assets/img/p8-1-2.png'>
							<p>実は病院に行った時、私たちはかかった医療費の1割~3割しか払っていません。残りはこの健康保険組合が負担してくれるのです。ですから窓口で保険証を出し、加入している組合を知らせます。</p>
							<p>必ずどこかの健保に入りみんなで支え合うことで、日本では低料金で質の高い医療が受けられるのです。</p>
							`,
							[{ text: "次へ", value: true }],
							current.image,
							['kenpo', 'stop'],
							["p8-1"]
						);

						let btn_text = myevent ? "サイコロを振る" : "次へ";

						items = [{ text: btn_text, value: true }];

						//２つ目のメッセージ
						if (interact) await repo.dialog.select(
							div`<h2>年金にも加入します</h2>
							<p>健康保険が医療の支え合いであるのに対し、年金は生活を支え合うための制度です。</p>
							<p style="text-align:center;"><strong>高齢や障害で働けなくなった人たちを支える</strong></p>
							<p>という仕組みなので、20歳になるとすべての人が「国民年金」に加入します。さらに会社員の人は、「厚生年金」にも加入します。にも、というところがミソで・・・
							下の図を見て下さい。</p>
	
							<img src='${template_directory_url}/assets/img/p8-2-2.png'>
							<p>会社員は年金が2階建てになります。では、支払いも倍になるかというとそうではありません。会社が半分を負担してくれるので、自分で払うお金はむしろ安くなるのです。</p>
							<p>国民年金の人は1階だけなので将来受け取れる年金は、厚生年金の加入者に比べ、少なくなります。</p>
							<hr>
							<img src='${template_directory_url}/assets/img/p8-2-3.png'>`,
							items,
							current.image,
							['nenkin', 'stop'],
							["p8-2"]
						);

						switch (Number(option)) {
							case 1:
							case 2:
							case 3:
								if (myevent) {
									this.job = 1;
									this.isKenpo = true;
									this.isNenkin = true;
								}
								job_text = "「会社員」になり保険に加入しました。<br>ステータスが変化！";
								ev_move = 11;
								start = current.next[1][10];
								break;
							case 4:
							case 5:
								if (myevent) {
									this.job = 2;
								}
								job_text = "「フリーター」になりました。<br>ステータスが変化！<br>保険や年金は未加入です。<br>加入手続きのため、市役所に向かってください。";
								break;
							case 6:
								if (myevent) {
									this.job = 3;
								}
								job_text = "「自営業者」になりました。<br>ステータスが変化！<br>保険や年金は未加入です。<br>加入手続きのため、市役所に向かってください。";
								break;
						}

						if (myevent) {
							await repo.log.PostPlayerStatus(this);
						}

						//サイコロ結果
						if (interact) await repo.dialog.event_dice(
							div`<p>${job_text}</p>`,
							5000,
							['common'],
							Number(option)
						);

						await this.move(start, 400);
						repo.status_lock = false;
						break;
					case EventKind.Settlement:
						// ポップアップを表示
						//if (repo.init) await this.piece.popup();
						if (Array.isArray(current.next)) {
							const items = [
								{ text: "左ルート", value: 0 },
								{ text: "右ルート", value: 1 },
							];
							// 選択肢
							if (current.next.length > 1) {
								if (interact && option === "none") option = await repo.dialog.select<number>(
									div`${current.name.replace("\n", "<br>")}<p>${ev.message}</p>`, items, current.image
								);
							}

							// set zero
							if (option === "none") option = 0;
							const selected = items.find(item => item.value === option)?.text;

							// Push Message
							if (index === 0) messages.push(`Event: ${current.name}\n${ev.message}\nSelected [${selected ?? "unknown"}]`);
							// 次のセクションの0番目
							start = current.next[option][0];
							// コマを次のセルに動かす
							await this.move(start, 400);

							/*
							if (interact && myevent) {
								const now = Date.now();
								// 状態を送信
								await repo.log.postPlaylog({
									epoch: now,
									player: this.id,
									cellref: current.ref!,
									dice: result,
									option: option,
									commit: true,
									ev_chain: true,
								});
								// チャットログに表示
								repo.log.push({
									chat: false,
									epoch: now,
									player: this.id,
									messages: messages,
								});
							}

							let next;
							[next, option] = await this.handleNormalEvent(start, messages);
							if (interact && myevent) {
								const now = Date.now();
								// 状態を送信
								await repo.log.postPlaylog({
									epoch: now,
									player: this.id,
									cellref: next.ref!,
									dice: value,
									option: option,
									commit: false,
								});
								// チャットログに表示
								repo.log.push({
									chat: false,
									epoch: now,
									player: this.id,
									messages: messages,
								});
							}
							*/
						}
						//  else {
						// ここ通ることは無いとは思うけど型推論のために処理
						// start = current.next;
						// }
						// コマを次のセルに動かす
						// await this.move(start, 400);
						break;
					//保険によるルート分岐
					case EventKind.SettlementByInsurance:
						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);
							await this.piece.popup();
						}
						if (Array.isArray(current.next)) {
							btn_label = ev.btn_label ? ev.btn_label : `閉じる`;
							items = [{ text: btn_label, value: true }];

							if (interact && myevent) repo.dialog.select<boolean>(
								div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
								items,
								current.image,
								ev.evCategory,
								ev.voice
							);
							else if (interact) await repo.dialog.notify(
								div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
								8000,
								current.image,
								ev.evCategory,
								ev.voice
							);

							/*
							option = this.isKokuho ? 0 : 1

							// 次のセクションの0番目
							start = current.next[option][0]
							// コマを次のセルに動かす
							await this.move(start, 400)
							*/
						}

						break;
				}
			} else if (Array.isArray(ev.value)) {
				// 未使用処理
			} else {
				// イベント値がその他(無い)場合
				switch (ev.kind) {
					case EventKind.Goal:
						//ゴール済のプレイヤーが居るか判定
						const someone_goaled = repo.players.some(player => player.isGoaled);

						// ゴール判定を送信
						if (myevent) await postGoal();
						this.isGoaled = true;

						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							repo.se.play("${template_directory_url}/assets/audio/se_goal.mp3");
							await this.piece.popup();
						}
						if (interact && myevent) {
							const now = Date.now();
							// 状態を送信
							await repo.log.postPlaylog({
								epoch: now,
								player: this.id,
								cellref: current.ref!,
								dice: 0,
								option: option,
								commit: true,
							});
						}

						//初めてゴールしたプレイヤーのときだけダイアログを表示
						if (interact && !someone_goaled) await repo.dialog.select<boolean>(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							[{ text: `閉じる`, value: true }],
							current.image,
							ev.evCategory,
							ev.voice
						);

						if (repo.move < 0) {
							repo.bgm.play(`${home_url}${template_directory_url}/assets/audio/bgm_goal.mp3`);
						}

						break;
				}
			}
		}

		// 選択した値を返す
		return [current, option];
	};

	// 通過強制イベントの反映
	/**
	 * 通過強制イベントの反映
	 * @param events 実行するイベント
	 * @returns 強制ストップかどうか
	 */
	async handleForceEvent(current: Cell, messages: string[] = []) {
		const myevent = this.id == repo.current.id;
		const interact = repo.init;

		for (const [index, ev] of (current.events ?? []).entries()) {
			switch (ev.kind) {
				case EventKind.ForceStop:
					return true;
					break;
				case EventKind.ForceStopP35:
					if (repo.current.job == 2 || repo.current.job == 3) {
						return true;
					}
					break;
				case EventKind.ForceStopOnce:
					//通過済クラスがついていたらスルーさせる
					if (document.querySelector(`.passed[data-evid="${current.evid}"]`)) {
					} else {
						if (interact && myevent) {
							setTimeout(function () {
								repo.log.postOnceStoplog(current.evid);	//このマスを通過済に
							}, 2000);
						}
						return true;
					}
					break;
				case EventKind.Wait:
					if (myevent) {
						let ev112Passes = Number(repo.ev_112_passes) + 1; // ev_112通過人数の管理
						console.log("112pass", ev112Passes);
						console.log("PLlength", repo.players.length);
						console.log("IF_BOOLEAN", ev112Passes !== repo.players.length);
						await repo.log.updateEv112Passes(ev112Passes); // ev_112通過人数更新
					}
					break;
			}

			if (typeof ev.value == "number") {
				const value = ev.value;
				let result = 0;
				switch (ev.kind) {
					case EventKind.Mayor:
						// SEを再生
						// ポップアップを表示
						if (repo.init) {
							repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);
							await this.piece.popup();
						}
						if (interact && myevent) await repo.dialog.select<boolean>(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							[{ text: `閉じる`, value: true }],
							current.image,
							ev.evCategory
						);
						else if (interact) await repo.dialog.notify(
							div`<h2>${current.name.replace("\n", "<br>")}</h2>${ev.message}`,
							8000,
							current.image,
							ev.evCategory
						);
						result = ev.value;
						this.balance += ev.value;
						if (index === 0) messages.push(`Event: Pay day, get ${Math.round(Math.abs(result)).toLocaleString()} USD`);
						break;
				}
			}
		}
		// ステータスを更新
		//repo.status.updateStatus()
	}

	async roll(): Promise<void> {
		// ロックチェック
		if (repo.dice.is_lock) return;
		repo.dice.lock();
		repo.dice.area.classList.add("inactive");

		// TODO: 必要かチェック
		// 縮小を戻す
		// if (repo.board.area.classList.contains("shrink")) repo.board.shrink();
		// ダイスロール
		let result = repo.debug
			? parseInt(prompt("デバッグモード中は何マス進むかを指定します", "10")!)
			: await repo.dice.rollAnimation();
		if (isNaN(result)) {
			repo.dice.unlock();
			return;
		}

		repo.se.play("${template_directory_url}/assets/audio/se_dice.mp3");
		// 中心を現在地に一旦戻す
		repo.board.focus(this.piece.current);
		await sleep(600);
		// コマの移動
		const messages: string[] = [];
		messages.push(`${this.name} rolls the dice and gets a ${result}`);
		// 移動結果を配列に纏める
		const moves: Cell[] = [];
		const [current, flag] = await this.forward(result, this.piece.current, messages, moves);
		// 強制停止イベントの場合は直前まで進める
		// 未コミット
		repo.log.postPlaylog({
			epoch: Date.now(),
			player: this.id,
			cellref: current.ref!,
			dice: result,
			commit: false,
		});
		// 移動の実行
		// console.log(moves);
		// console.log(current);
		for (const item of moves) {
			await this.move(item, repo.init ? 400 : 0);
			await this.handleForceEvent(item, messages);
		}
		// 通常イベント
		console.log("current", current);

		const [next, option] = await this.handleNormalEvent(current, messages);

		const now = Date.now();

		//特定のイベントのみ、handleNormalEvent内で先にcommitさせるようにする
		//const fast_commit_list = ["6","15", "35_dummy", "73"]
		const not_fast_commit_list = ["42", "45a_dummy", "45b_dummy"];

		if (not_fast_commit_list.includes(current.evid)) {
			// 状態を送信
			await repo.log.postPlaylog({
				epoch: now,
				player: this.id,
				cellref: next.ref!,
				dice: result,
				option: option,
				commit: true,
			});
		}
		// チャットログに表示
		repo.log.push({
			chat: false,
			epoch: now,
			player: this.id,
			messages: messages,
		});

		// 移動が終わったのでロック解除
		// repo.dice.unlock();
	}

	async sync(update: PlayLog): Promise<void> {
		// 移動先のセルを特定
		const next = repo.board.trace(update.cellref);
		// 移動手順の配列を生成
		let result: Cell[] = [];
		let current: Cell = this.piece.current;
		console.log([this, next, current]);

		// 初期位置の設定の場合は走査しない
		if (current && next.ref) {
			// 一致するセルを探す
			while (!current.ref?.every((v, i) => next.ref && v === next.ref[i])) {
				const temp = Array.isArray(current.next)
					? current.next[next.ref[2]][0]
					: current.next;
				// ルートが正しく追えなかった場合は強制的に移動
				if (!temp) {
					alert("データ同期エラー：データがリセットされたか、ルームへの再入室があった等でプレイデータが破損した可能性がございます。\
プレイを継続するために、適切な現在地を探して移動します（適切な位置が見つからない場合は初期位置に戻ります）。");
					result.push(next);
					break;
				}
				result.push(temp);
				current = temp;
			}
		} else {
			// 初期位置の設定の場合
			result.push(next);
		}
		if (this.piece.current && repo.init) {
			repo.board.focus(this.piece.current);
			await sleep(200);
		}
		// メッセージの準備
		const messages: string[] = [];

		// currentとnextが異なる場合は移動を実行
		if (!this.piece.current || !next.ref!.every((value, index) => this.piece.current.ref![index] == value)) {
			// 0ならゲーム参加メッセージ
			if (update.dice > 0) {
				messages.push(`${this.name} rolls the dice and gets a ${update.dice}`);
			} else {
				messages.push(`${this.name} がゲームに参加しました`);
			}
			// 移動を実行
			for (const item of result) {
				await this.move(item, repo.init ? 400 : 0);
				await this.handleForceEvent(item, messages);
			}
		}
		// コミットされている場合はその地点のイベントを実行
		if (update.commit) await this.handleNormalEvent(next, messages, update.option);
		// ログを追加
		repo.log.push({
			chat: false,
			epoch: update.epoch,
			player: this.id,
			messages: messages,
		});
		// ステータスを更新
		//repo.status.updateStatus()
	}
}
