import { repo } from "../repo";

export class Audio {
    readonly control: HTMLMediaElement;

    constructor(control: HTMLMediaElement) {
        this.control = control;
        this.control.autoplay = false;
    }

    async play(file: string) {
        try {
            this.control.pause();
            this.control.currentTime = 0;
            this.control.src = file;
            await this.control.play();
        } catch (error) {
            console.error(error);
        }
    }

    get spec() {
        return repo.current.piece.current.ref![0] == "Main" ? "u" : "r";
    }
}
