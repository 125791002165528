import { home_url } from "../constant";
import { CellRef } from "../model/models";
import { Player } from "../model/player";
import { repo } from "../repo";
import { sleep } from "../util";

export class Log {
    /** DOM */
    readonly area: HTMLElement;

    /** プレイログ */
    readonly play: PlayLog[] = [];

    /** チャットログ */
    readonly chat: ChatLog[] = [];

    /** 表示更新用配列 */
    private readonly logs: LogItem[] = [];

    constructor(area: HTMLElement) {
        this.area = area;
    }

    /** 最新のログにスクロール */
    scroll() {
        this.area.scrollTop = this.area.scrollHeight;
    }

    /** ログを更新 */
    private update() {
        this.area.innerText = '';
        for (const log of this.logs.sort((a, b) => a.epoch - b.epoch).filter(log => log.messages.length !== 0)) {
            const item = document.createElement('div');
            item.dataset.name = repo.getPlayerById(log.player)?.name;
            item.dataset.time = new Date(log.epoch).toLocaleTimeString();
            if (log.player == repo.current.id) item.classList.add('self');
            if (log.chat) {
                item.classList.add('chat');
            }
            // } else {
            //     item.innerHTML = '<p>' + log.messages.join('</p><p>') + '</p>';
            // }
            item.innerText = log.messages.join('\n');
            this.area.appendChild(item);
        }
        this.scroll();
    }

    /** ログ表示を更新 */
    push(update: LogItem) {
        this.logs.push(update);
        this.update();
    }

    // isPlaylog(log: any): log is PlayLog {
    //     return 'dice' in log;
    // }

    async moveCheck(): Promise<number | undefined> {
        const params = new URLSearchParams();
        params.append('action', 'move_check');
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();
        // 取得に成功
        if (json["status"] == 200) {
            const move: number = json["move"];
            return move;
        }
        // なにもなかった
        console.error("Failed to get data from server.");
        return undefined;
    }

    async getPlaylog(): Promise<[PlayLog[], number, number, boolean] | undefined> {
        const params = new URLSearchParams();
        params.append('action', 'get_play_log');
        params.append('skip', `${this.play.length}`);
        let result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params }).catch(
            (error) => {
                console.log(error);
            }
        );
        if (result === undefined) {
            return;
        }

        const json = await result.json();
        // 取得に成功
        if (json["status"] == 200) {
            const data: PlayLog[] = json["playlog"] ?? [];
            const turn: number = json["turn"];
            const move: number = json["move"];
            const goal: boolean = json["goal"];
            const players_data: object = json["players"];
            repo.once_stop_log = json["once_stop_log"];
            repo.ev_112_passes = Number(json["ev_112_passes"]);

            repo.players.forEach((pl: Player, index: number) => {
                pl.RestCount = Number(players_data[index].rest_count);  //休み回数は手番以外でも更新されるため、常時更新
                pl.isTainou = players_data[index].istainou;
                if (repo.current.id == pl.id) return;
                pl.isKenpo = players_data[index].iskenpo;
                pl.isKokuho = players_data[index].iskokuho;
                pl.isMeisai = players_data[index].ismeisai;
                pl.isNenkin = players_data[index].isnenkin;
                pl.job = Number(players_data[index].job);
                //console.log(pl);
            });

            // AFKから戻ってきた
            if (0 <= move && move < repo.players.length) {
                if (repo.players[move].isAfk && repo.turn != 0 && turn != repo.turn) {
                    repo.players[move].isAfk = false;
                    repo.log.push({
                        chat: false,
                        epoch: Date.now(),
                        player: repo.players[move].id,
                        messages: [`${repo.players[move].name} is reconnected.`],
                    });
                }
            }

            // AFKになったプレイヤー
            const afk = repo.players.find(player => player.id == json["afk"]);
            if (afk && !afk.isAfk) {
                afk.isAfk = true;
                repo.log.push({
                    chat: false,
                    epoch: Date.now(),
                    player: afk.id,
                    messages: [`${afk.name} is disconnected.`],
                });
            }
            // CellRefがStringの場合は変換
            data.forEach(item => item["cellref"] = Array.isArray(item["cellref"]) ? item["cellref"] : JSON.parse(item["cellref"]));

            // ログ一覧に追記
            this.play.push(...data);

            // 更新部分のみ返す
            return [data, turn, move, goal];
        }
        // なにもなかった
        console.error("Failed to get data from server.");
        return undefined;
    }

    async postPlaylog(data: PlayLog): Promise<PlayLog[]> {
        const params = new URLSearchParams();
        params.append('action', 'post_play_log');
        params.append('data', JSON.stringify(data));
        let temp = JSON.stringify(data);
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();
        // 取得に成功
        if (json["status"] == 200) {
            // 更新部分のみ返す
            const data: PlayLog[] = json["playlog"];
            console.log("Success", temp);
            return data;
        }

        // なにもなかった
        console.log("Fail", temp);
        console.log("Json", json);
        console.error("Failed to post log.");

        if (json["msg"] == "Not your turn") {
            return [];
        } else {
            await sleep(3000);
            return this.postPlaylog(data);
        }
    }

    async postOnceStoplog(data: string): Promise<boolean> {
        const params = new URLSearchParams();
        params.append('action', 'post_once_stop_log');
        params.append('data', data);
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();

        // 取得に成功
        if (json["status"] == 200) {
            return true;
        }

        // なにもなかった
        console.error("Failed to get data from server.");
        return false;
    }

    // ev_112通過人数更新
    async updateEv112Passes(data: number): Promise<boolean> {
        const params = new URLSearchParams();
        params.append('action', 'post_ev_112_passes');
        params.append('data', data.toString());
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();

        // 取得に成功
        if (json["status"] == 200) {
            return true;
        }

        // なにもなかった
        console.error("Failed to get data from server.");
        return false;
    }

    async PostPlayerStatus(pl: Player): Promise<boolean> {
        const params = new URLSearchParams();
        params.append('action', 'post_player_status');
        params.append('job', `${pl.job}`);
        params.append('last_ev_dice', `${pl.last_ev_dice}`);
        params.append('isKenpo', `${pl.isKenpo}`);
        params.append('isKokuho', `${pl.isKokuho}`);
        params.append('isNenkin', `${pl.isNenkin}`);
        params.append('isMeisai', `${pl.isMeisai}`);
        params.append('isTainou', `${pl.isTainou}`);
        params.append('RestCount', `${pl.RestCount}`);

        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();

        // 取得に成功
        if (json["status"] == 200) {
            return true;
        }

        // なにもなかった
        console.error("Failed to get data from server.");
        return false;
    }

    async RecoverWait(): Promise<boolean> {
        console.log("recover_fired");

        const params = new URLSearchParams();
        params.append('action', 'recover_wait');

        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();

        // 取得に成功
        if (json["status"] == 200) {
            return true;
        }

        // なにもなかった
        console.error("Failed to get data from server.");
        return false;
    }

    async postQuizCount(): Promise<boolean> {
        const params = new URLSearchParams();
        params.append('action', 'post_once_stop_log');
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();

        // 取得に成功
        if (json["status"] == 200) {
            return true;
        }

        // なにもなかった
        console.error("Failed to get data from server.");
        return false;
    }

    async getChatlog(): Promise<ChatLog[] | undefined> {
        const params = new URLSearchParams();
        params.append('action', 'get_chat_log');
        params.append('skip', `${this.chat.length}`);
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();
        // 取得に成功
        if (json["status"] == 200) {
            const data: ChatLog[] = json["chatlog"] ?? [];
            // ログ一覧に追記
            this.chat.push(...data);
            // 更新部分のみ返す
            return data;
        }
        // なにもなかった
        console.error("Failed to get data from server.");
        return undefined;
    }

    async postChatlog(data: ChatLog): Promise<ChatLog[]> {
        const params = new URLSearchParams();
        params.append('action', 'post_chat_log');
        params.append('data', JSON.stringify(data));
        const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
        const json = await result.json();
        // 取得に成功
        if (json["status"] == 200) {
            // 更新部分のみ返す
            const data: ChatLog[] = json["chatlog"];
            return data;
        }
        // なにもなかった
        console.error("Failed to get data from server.");
        return [];
    }
}

export interface PlayLog {
    epoch: number,
    player: number,
    dice: number,
    cellref: CellRef,
    option?: number | "none" | "wait",
    commit: boolean,
    ev_chain?: boolean,
}

export interface ChatLog {
    epoch: number,
    player: number,
    message: string,
}

export interface LogItem {
    chat: boolean,
    epoch: number,
    player: number,
    messages: string[],
}
