import { home_url, isDemo, template_directory_url } from "./constant";
import { GameStart, getPlayers, isReady, joinGame, postOption } from "./game";
import { Player } from "./model/player";
import { repo } from "./repo";
import { div, sleep } from "./util";
import { PlayLog } from "./view/log";
import { Piece } from "./view/piece";

function ebi<T extends HTMLElement>(id: string) {
    return document.getElementById(id) as T;
}

async function main() {
    // セットアップ
    ebi<HTMLElement>("fold")?.addEventListener("click", () => ebi<HTMLElement>("fold")!.innerText = repo.log.area.classList.toggle("open") ? "Close" : "Open");

    //ebi<HTMLElement>("shrink")?.addEventListener("click", () => ebi<HTMLElement>("shrink")!.innerText = repo.board.shrink() ? "＋" : "−")
    ebi<HTMLElement>("shrink_minus")?.addEventListener("click", () => {
        if (!ebi<HTMLElement>("shrink_minus").classList.contains("disabled")) {
            repo.board.shrink();
            ebi<HTMLElement>("shrink_minus").classList.add("disabled");
            ebi<HTMLElement>("shrink_plus").classList.remove("disabled");
        }
    });

    ebi<HTMLElement>("shrink_plus")?.addEventListener("click", () => {
        if (!ebi<HTMLElement>("shrink_plus").classList.contains("disabled")) {
            repo.board.shrink();
            ebi<HTMLElement>("shrink_plus").classList.add("disabled");
            ebi<HTMLElement>("shrink_minus").classList.remove("disabled");
        }
    });

    // サイコロの操作を停止
    repo.dice.lock();
    repo.dice.area.classList.add("inactive");
    repo.dice.area.addEventListener("click", () => repo.current.roll());

    // サーバーからの情報を取得
    const server = ebi<HTMLElement>("app")?.dataset;
    if (server?.room == undefined || server?.player == undefined) {
        alert("Failed to get room / player data form server.");
        window.location.href = home_url;
        return;
    }

    // BGMの音量を下げる
    repo.se.control.volume = 0.6;
    repo.bgm.control.volume = 0.2;

    // 正規化
    const room_id = parseInt(server.room);
    const player_id = parseInt(server.player);

    // ゲームに参加
    repo.message.update("ゲームへ参加しようとしています...");
    const me = await joinGame(room_id);

    // データの取得に失敗した
    if (!me) {
        alert("ゲームへの参加に失敗しました, 参加先のルームをご確認ください.");
        window.location.href = home_url;
        return;
    }

    const resetMessage = isDemo
        ? "現在のプレイデータをリセットしますか？"
        : "現在のプレイデータをリセットしますか？\n※参加者全員に影響があります。必要な場合を除いて押さないでください。";

    const resetMessag2 = isDemo
        ? ""
        : "\n他の参加者がいる場合は、全員がリセットを押したことを必ずご確認ください。";

    document.getElementById("data_reset")?.addEventListener("mouseup", async function () {
        if (confirm(resetMessage)) {
            const params = new URLSearchParams();
            params.append('action', 'remove_play_log');
            const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
            const json = await result.json();

            // 取得に成功
            if (json["status"] == 200) {
                alert(`データをリセットしました。${resetMessag2}\nルーム選択画面に戻りますのでルームを作り直し、始めからやり直してください。`);
                window.location.href = home_url;
            } else {
                alert("データの初期化に失敗しました。しばらく待ってから再度お試しください。");
                return false;
            }
        }
    });

    const everyoneMessage = isDemo
        ? ""
        : "\n・必ずプレイヤー全員がリセットを押してください。<br>";

    const slide_content_suffix = isDemo
        ? ""
        : "プレイヤー全員で行ってください。";

    // 参加済みでない場合は説明等を表示
    let slide_content = div`
		<h2>不具合を起こさないために</h2>
		<img src='${template_directory_url}/assets/img/n1-1.png'>
		<p>プレイ中にブラウザの「戻る」「進む」「更新」「ホーム」を押すと不具合が出ます。押さないでください。また、フリックやスワイプでの「ページ移動」も不具合が出ます。注意してください。</p>
		<hr>
		<h2>動作がおかしいな、と思ったら</h2>
		<h3>①リセットボタンを押す</h3>
		<img src='${template_directory_url}/assets/img/n1-3.png'>
		<p>
            ${everyoneMessage}
		    ・リセットボタンを押すとルーム選択画面に戻ります。ルームを作り直して始めからやり直します。
        </p>
		<hr>
		<h3>②ウインドウを閉じてログインしなおす</h3>
		<p>リセットを押しても不具合が解消しない場合は、ブラウザのウインドウを閉じて、ログインし直します。 ${slide_content_suffix}</p>
    `;

    slide_content.classList.add("slide-wrapper");
    await repo.dialog.custom<void>(
        slide_content,
        [
            {
                text: "次へ",
                callback: (res) => {
                    res();
                },
            }
        ],
        "",
        ['first_msg', 'caution'],
    );

    if (!isDemo) {
        slide_content = div`
	    	<h2 style="padding-left: 5px; padding-right: 5px;">順番は右上のプレイヤーから、「時計回り」</h2>
	    	<img src='${template_directory_url}/assets/img/n2-1.png'>
	    	<!--<p>プレイ中にブラウザの「戻る」「進む」「更新」「ホーム」を押すと不具合が出ます。押さないでください。また、フリックやスワイプでの「ページ移動」も不具合が出ます。注意してください。</p>-->
	    	<p>　</p>
	    	<h2>順番になったら自分の近くにサイコロが移動</h2>
	    	<img src='${template_directory_url}/assets/img/n2-2.png'>
	    	<p>自分の順番がくると、サイコロがキャラクターの近くに移動してきます。サイコロがフワフワと動いたら押してください。</p>
        `;
        slide_content.classList.add("slide-wrapper");
        await repo.dialog.custom<void>(
            slide_content,
            [
                {
                    text: "次へ",
                    callback: (res) => {
                        res();
                    },
                }
            ],
            "",
            ['first_msg', 'caution2'],
        );
    }

    slide_content = div`
        <div class="slide show">
			準備が出来たらボタンを押してください。
        </div>
    `;
    slide_content.classList.add("slide-wrapper");
    await repo.dialog.custom<void>(
        slide_content,
        [
            {
                text: "準備OK",
                callback: (res) => {
                    res();
                },
            }
        ],
        "",
        ['common']
    );

    // 自分が何人目のプレイヤーかを取得
    let my_index;
    const temp_players = await getPlayers();
    for (const value of temp_players) {
        // ユーザーID
        const user_id = parseInt(value["user"]);
        const index = parseInt(value["index"]);
        if (user_id == player_id) my_index = index;
    }

    if (!me['join']) {
        // 名前を入力
        const user_content = div`
                <div class="user-option">
                    <h2>1. 名前を入力してください。</h2>
                    <input class="input-name">

                    <h2>2. キャラクターを選択してください</h2>
                    <div class="input-charas">
                        <div class="input-chara" data-index="1"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-1.webp"></div>
                        <div class="input-chara" data-index="2"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-2.webp"></div>
                        <div class="input-chara" data-index="3"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-3.webp"></div>
                        <div class="input-chara" data-index="4"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-4.webp"></div>
                        <div class="input-chara" data-index="5"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-5.webp"></div>
                        <div class="input-chara" data-index="6"><img src="${template_directory_url}/assets/img/pl_${my_index + 1}-6.webp"></div>
                    </div>
                </div>
            `;
        const input_name = user_content.querySelector<HTMLInputElement>(".input-name")!;
        const input_charas = user_content.querySelectorAll<HTMLElement>(".input-chara")!;
        let select_chara: number | undefined = undefined;
        input_charas.forEach(chara => {
            chara.addEventListener("click", () => {
                input_charas.forEach(item => item.classList.remove("select"));
                chara.classList.add("select");
                if (chara.dataset.index) select_chara = parseInt(chara.dataset.index);
            });
        });
        const result = await postOption(await repo.dialog.custom<[string, number]>(user_content,
            [
                {
                    text: "決定",
                    callback: (res) => {
                        if (!input_name.value) return;
                        if (select_chara === undefined) return;
                        res([input_name.value, select_chara]);
                    },
                }
            ],
            "",
            ["common", "start_config"],
        ));
    }

    // プレイヤーを待つ
    repo.message.update("他のプレイヤーの参加を待っています.");

    const start_button_ol = document.createElement("div");
    start_button_ol.classList.add("dialog");
    start_button_ol.classList.add("show");
    start_button_ol.classList.add("start_dialog");

    const start_button_wrap = document.createElement("div");
    start_button_wrap.classList.add("content");
    start_button_wrap.classList.add("common");

    const start_button_guide = document.createElement("p");
    start_button_guide.innerHTML = "あなたがこのルームのチームリーダーです。<br>ルーム内の全員のプレイヤーの準備が整ったら<br>ゲーム開始ボタンを押してください";

    const start_button = document.createElement("div");
    start_button.classList.add("start_button");
    start_button.classList.add("button");
    start_button.innerText = "ゲームを開始";

    const startMessage = isDemo
        ? "ゲームを開始しますか？"
        : "ゲームを開始しますか？\n※参加済みでないプレイヤーがいる状態でゲームを開始すると、\nそのプレイヤーはルームから自動的に退出となります。";

    const onStartHandler = async function () {
        if (confirm(startMessage)) {
            const start_result = await GameStart();
            if (start_result) {
                document.querySelector(".start_dialog")?.remove();
            } else {
                alert("ゲームの開始に失敗しました。時間を置いて再度お試しください。");
            }
        }
    };

    start_button.addEventListener("click", onStartHandler);

    start_button_wrap.appendChild(start_button_guide);
    start_button_wrap.appendChild(start_button);
    start_button_ol.appendChild(start_button_wrap);

    let first_loop = true;

    while (true) {
        if (first_loop) {
            if (my_index == 0) {
                if (isDemo) {
                    await onStartHandler();
                } else {
                    document.getElementById("app")?.appendChild(start_button_ol);
                }
            }
            first_loop = false;
        }
        const players = await getPlayers();
        for (const value of players) {
            // ユーザーID
            const user_id = parseInt(value["user"]);
            if (isNaN(user_id)) continue;
            // 参加してないプレイヤーは除外
            if (!value["join"]) continue;
            // 既にプレイヤーが存在すれば除外
            if (repo.players.find(player => player.id == user_id)) continue;
            // 名前と画像を取得
            const index = parseInt(value["index"]);
            const name = `${value["name"]}`;
            const chara = parseInt(value["chara"]);
            // プレイヤーを追加
            const player = new Player(index, user_id, name, new Piece(name, chara, index), chara);
            if (user_id == player_id) repo.current = player;
            repo.players.push(player);
            repo.players.sort((a, b) => a.index - b.index);
        }
        // ステータスを更新
        repo.status.updateStatus();

        let result = await repo.log.getPlaylog();
        if (result) {
            let [data] = result;
            for (const update of data) await repo.getPlayerById(update.player)?.sync(update);
        }

        // 全員参加したら抜ける
        //if (players.every(player => player["join"])) break;
        if (await isReady()) break;
        await sleep(3000);
    }

    // ゲームの開始を待つ
    if (!await isReady()) repo.message.update("ゲームの開始を待っています..");
    while (!await isReady()) await sleep(5000);

    repo.players.splice(0, repo.players.length);
    const piecesElement = document.getElementById('pieces');
    if (piecesElement) {
        piecesElement.innerHTML = '';
    }


    for (const value of await getPlayers()) {
        // ユーザーID
        const user_id = parseInt(value["user"]);
        if (isNaN(user_id)) continue;
        // 参加してないプレイヤーは除外
        if (!value["join"]) continue;
        // 既にプレイヤーが存在すれば除外
        if (repo.players.find(player => player.id == user_id)) continue;
        // 名前と画像を取得
        const index = parseInt(value["index"]);
        const name = `${value["name"]}`;
        const chara = parseInt(value["chara"]);
        // プレイヤーを追加
        const player = new Player(index, user_id, name, new Piece(name, chara, index), chara);
        if (user_id == player_id) repo.current = player;
        repo.players.push(player);
        repo.players.sort((a, b) => a.index - b.index);
    }

    console.log("players生成直後", repo.players);

    // 現在の状態を読み込み
    repo.message.update("読み込み中...");
    let result = await repo.log.getPlaylog();
    if (result) for (const update of result[0]) await repo.getPlayerById(update.player)?.sync(update);
    // ステータスを更新
    repo.status.updateStatus();

    // 初期地点の登録が済んでない場合は登録する。
    repo.message.update("Game starting...");

    // 更新情報を作成
    const update: PlayLog = {
        epoch: Date.now(),
        player: repo.current.id,
        dice: 0,
        cellref: ["Main", 0, 0, 0],
        option: "none",
        commit: true,
    };

    // 初期地点に移動
    repo.current.sync(update);
    repo.board.focus(repo.current.piece.current);

    // 初期化が完了したことを通知
    repo.init = true;
    await sleep(400);

    // ゲームの開始を待つ
    repo.message.update("他のプレイヤーの参加を待っています…");
    while (!repo.players.every(player => player.piece.current)) {
        // 同期の実行
        result = await repo.log.getPlaylog();
        if (result) {
            let [data] = result;
            for (const update of data) if (repo.current.id != update.player) await repo.getPlayerById(update.player)?.sync(update);
        }

        // ステータスを更新
        repo.status.updateStatus();

        if (await isReady()) break;
        await sleep(1000);
    }

    // ゲームが開始
    repo.message.update("ゲーム開始");
    //repo.bgm.play(`${template_directory_url}/assets/audio/bgm_${repo.bgm.spec}1.mp3`)

    await repo.dialog.select<boolean>(
        div`<h2>「ライフ・リテラシー」って、なに?</h2>
		<img src="${template_directory_url}/assets/img/p0-2-2.png" style="display: block;">
		<p style="text-align: center">「入門!ライフ・リテラシー ゲーム」は<br>
		現実社会の予行練習をする、超リアルな人生ゲーム。<br>
		税や社会保障、働くうえでトラブルに巻き込まれないための基本を学習します。</p>
		<h2>なぜ「ライフ・リテラシー」が<br>必要なの?</h2>
		<p>　就職するとお給料をもらいますが、その中から税金や社会保険料を払わなければなりません。
		健康保険をはじめ、あたり前と思っていた身近な制度は、多くの人の税金や保険料でまかなわれているのです。<br>
		社会に出るということは、一方的な受益者から負担の義務を負う側に回る、ということです。</p>

		<p>　同時に自分の身は自分で守らなければならない、本当の意味での巣立ちの時になるのです。
		「ライフ・リテラシー」の知識を持たずに社会に出ることは、防具をつけず戦いに参加するのと同じ。
		プレイ前に、まずその危険性をしっかりと認識しましょう。</p>

		<h2>ゲームのコツ</h2>
		<p>　実生活で使える知識にするためには、自ら学ぶ姿勢が大事です。
		プレイヤーは、ストップマスに表示されるテキストをできる限り、音読しましょう。
		発音し、自分自身も含め全員が理解できるよう努めましょう。<br>
		　特に<span style="color: #e50012;">給与明細書</span>の解説はよく聞いて下さい。ここに載っているのは日本社会の背骨のような制度ばかりです。
		社会生活を送る際の基礎になるので、まずは「明細書をちゃんと読める」を目指しましょう。<br>
		　ではゲームを始めましょう!あなたのもうひとつの人生の始まりです(^^)/</p>
		`,
        [{ text: `ゲームスタート！`, value: true }],
        "",
        ["first_msg", "life"],
        ["p0-2"]
    );

    await sleep(1000);

    repo.se.play(`${template_directory_url}/assets/audio/se_receive.mp3`);

    await repo.dialog.select<boolean>(
        div`<h2>ゲーム開始</h2>
		<p>コンビニでアルバイトを始める。</p>`,
        [{ text: `閉じる`, value: true }],
        "",
        ["common"],
        ["p0-2"]
    );

    //全員の初期位置（current）を強制的に更新
    repo.players.forEach(player => player.sync(update));

    // ゲームのメインループ
    while (true) {
        // インターバル
        await sleep(2000);

        if (document.querySelector(".dialog.show")) continue;

        if (repo.turn != 0) {
            document.querySelector('#dice')?.setAttribute("data-turn", `${repo.move}`);
        }

        // 同期の実行
        result = await repo.log.getPlaylog();

        if (!result) {
            console.error("Failed to getPlaylog");
            continue;
        }
        let [data, turn, move] = result;
        for (const update of data) if (repo.current.id != update.player) await repo.getPlayerById(update.player)?.sync(update);

        // ターン制御
        if ((repo.turn !== turn || repo.move !== move) && turn > 0) {
            // ターン数の更新
            repo.turn = turn;
            repo.move = move;

            // ターン数が-1ならゲーム終了判定
            if (move === -1) {
                // ゲーム終了
                repo.status.updateStatus();
                repo.message.updateHTML(`<a href="${home_url}/menu">ゲーム終了</a>`);
                break;
            }
            // 現在のプレイヤーのターンであるか
            if (repo.current.id === repo.players[move].id) {
                const last = repo.log.play[repo.log.play.length - 1];
                // console.log(last);
                if (last.commit) {
                    repo.message.update("あなたの番です。サイコロを振ってください。");
                    repo.board.focus(repo.current.piece.current);
                    repo.dice.unlock();
                    repo.dice.area.classList.add("active");
                    repo.dice.area.classList.remove("inactive");
                } else {
                    console.log("要チェック分岐", repo);
                    const messages: string[] = [];
                    const [next, option] = await repo.current.handleNormalEvent(repo.current.piece.current, messages);
                    const now = Date.now();
                    // 状態を送信
                    await repo.log.postPlaylog({
                        epoch: now,
                        player: repo.current.id,
                        cellref: next.ref!,
                        dice: last.dice,
                        option: option,
                        commit: true,
                    });
                    // チャットログに表示
                    repo.log.push({
                        chat: false,
                        epoch: now,
                        player: repo.current.id,
                        messages: messages,
                    });
                }
            } else {
                repo.message.update(`「${repo.players[move].name}」さんの番です。`);
            }
        }

        //ボードの最初の人だけ通過のイベント制御
        if (repo.once_stop_log) {
            let stop_logs = repo.once_stop_log.split(",");
            for (let i = 0; i < stop_logs.length; i++) {
                document.querySelector(`.item[data-evid="${parseInt(stop_logs[i])}"]`)?.classList?.add("passed");
            }
        }

        // ステータスを更新
        repo.status.updateStatus();
    }

    while (true) {
        // インターバル
        await sleep(2000);
        if (document.querySelector(".dialog.show")) continue;
        repo.bgm.play(`${template_directory_url}/assets/audio/bgm_goal.mp3`);
        break;
    }
}

// Run app
document.addEventListener("DOMContentLoaded", main);
