import { repo } from "../repo";

export class Chat {
    /** DOM */
    readonly input: HTMLInputElement;

    /** DOM */
    readonly button: HTMLElement;

    constructor(input: HTMLInputElement, button: HTMLElement) {
        this.input = input;
        let shift = false;
        this.input.addEventListener("keydown", (ev) => {
            switch (ev.key) {
                case "Shift":
                    shift = true;
                    break;
                case "Enter":
                    if (shift) break;
                    ev.preventDefault();
                    this.send();
                    break;
                default:
                    break;
            }
        });
        this.input.addEventListener("keyup", (ev) => {
            switch (ev.key) {
                case "Shift":
                    shift = false;
                    break;
                default:
                    break;
            }
        });
        this.button = button;
        this.button.addEventListener("click", () => this.send());
    }

    send() {
        if (this.input.value.trim() == "") return;
        repo.log.postChatlog({
            epoch: Date.now(),
            player: repo.current.id,
            message: this.input.value,
        });
        this.input.value = "";
    }
}
