import { template_directory_url } from "../constant";
import { course } from "../model/course";
import { Cell, CellRef } from "../model/models";
import { repo } from "../repo";
import { div, sleep } from "../util";

const PIECE_MARGIN = [
    { x: 20, y: 0, },
    { x: 10, y: 0, },
    { x: -10, y: 0, },
    { x: 0, y: 0, },
    { x: -20, y: 0, },
    { x: -30, y: 0, },
];

export class Piece {
    /** DOMの領域 */
    readonly area: HTMLElement;
    readonly pop: HTMLElement;
    /** 現在の位置 */
    private _current!: Cell;
    /** 現在の位置 */
    get current() {
        return this._current;
    }
    /** コマを作成する。 */
    constructor(name: string, chara: number, index: number) {
        const margin = PIECE_MARGIN[index];
        this.area = div`
            <span class="name">
                <span>${name}</span>
            </span>
            <img src="${template_directory_url}/assets/img/koma${index+1}.webp"></img>
        `;
        this.area.classList.add("piece");
        this.area.style.marginLeft = `${margin.x}px`;
        this.area.style.marginTop = `${margin.y}px`;
        const init = course.Main[0][0][0];
        this.area.style.left = `${init.x}px`;
        this.area.style.top = `${init.y}px`;
        // this.area.dataset.name = name;
        this.pop = div``;
        this.pop.classList.add("popup");
        this.area.appendChild(this.pop);
        const pieces = document.getElementById("pieces")!;
        pieces.appendChild(this.area);
    }
    /**
     * 指定したセルにコマを移動します。
     * @param cell 移動先のセル
     */
    move(cell: Cell) {
        this.area.style.left = `${cell.x}px`;
        this.area.style.top = `${cell.y}px`;
        this._current = cell;
        if (repo.init) repo.se.play(`${template_directory_url}/assets/audio/se_car.mp3`);
    }
    /**
     * 指定したセルにコマを移動します。
     * @param cellref 移動先のセル
     */
    moveRef(cellref: CellRef) {
        const cell = repo.board.trace(cellref);
        this.area.style.left = `${cell.x}px`;
        this.area.style.top = `${cell.y}px`;
        this._current = cell;
    }
    /**
     * ポップアップを表示
     */
    async popup(): Promise<void> {
        if (!this.current.image) {
            await sleep(1600);
            return;
        }
        const img = document.createElement("img");
        img.src = this.current.image;
        this.pop.appendChild(img);
        this.pop.classList.add("show");
        await sleep(1600);
        this.pop.classList.remove("show");
        this.pop.innerText = "";
        return;
    }
}
