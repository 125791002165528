import { isDemo, template_directory_url } from "../constant";
import { Course, Direction, EventKind } from "./models";

const course_main: Course = {
    Main: [
        [
            [
                {
                    evid: "1",
                    name: "",
                    direction: Direction.None,
                    events: [{ evCategory: ['common'], message: ``, kind: EventKind.Start }],
                },
                {
                    evid: "2",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>さっそく遅刻する<br>スタートに戻る</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                },
                {
                    evid: "3",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "4",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>お客さんに恋をする<br>1つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "5",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "6",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>バイトテロ<br>いたずら画像をSNSに投稿<br>お店に損害を与えクビになる<br>1回休み</p>`, kind: EventKind.MsgNormal, value: -1, rest: 1 }],
                },
                {
                    evid: "7",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "8",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>初めてのバイト料で親にプレゼントを買う<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "9",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "10",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "11",
                    name: "バイトの残業代が<br>支払われていないことに気づく!",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['roudou', 'stop_first'], voice: ["p6"], message: `<p>残念ながら、違法な働きかたを強要する職場もあります。おかしいと感じたらひとりで悩まず、勇気を出して相談しましょう。<br>
						検索ワードは...</p><p><strong>「○○県 総合労働相談 」「法テラス 」、</strong>など。</p>
						<p>必ず公的機関を選んで下さい。税金で運営されているため無料です。学生でも消費税を払っている立派な納税者。相談料を前払いしたと考えて、どんどん活用しましょう。放置しても解決はしません。早めに相談する姿勢を徹底して下さい。</p>
						<p>また給与明細書は法的な証拠にもなる重要な書類。きちんと内容を確認し、大切に保管しましょう。給与明細をもらっていない場合はすぐに辞めて下さい。最低限の法律を守っていない会社です(所得税法違反)。</p>
						`, kind: EventKind.MsgNormal, value: -1
                        }
                    ],
                },
                {
                    evid: "12",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "13",
                    name: "",
                    direction: Direction.Right,
                    events: [{
                        evCategory: ['common'], message: `<p>学校を卒業する<br>
					2つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: true
                    }],
                },
                {
                    evid: "14",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "15",
                    name: "",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['nenkin', 'stop'], voice: ["p8-2"], btn_label: `サイコロを振る`,
                            message: ``, kind: EventKind.P8, value: 0, chain: false
                        },
                    ],
                },
            ],
        ],
        [
            [//フリーター・自営業ルート
                {
                    evid: "16",
                    name: "",
                    direction: Direction.Right,
                    events: [{ kind: EventKind.None }],
                },
                {
                    evid: "17",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "18",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "19",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "20",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "21",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "22",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "23",
                    name: "市役所で手続き",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        { evCategory: ['common', 'stop'], message: `<p>国民健康保険 保険証をもらい、年金の加入状況も確認</p><img src='${template_directory_url}/assets/img/p9-1.png'>`, kind: EventKind.P9, value: +1 }
                    ],
                    // P9
                },
                {
                    evid: "24",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "25",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "26",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "27",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "28",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>敬語がキチンと使えず、へこむ<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                    // P10
                },
                {
                    evid: "29",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "30",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>無事に研修が終わる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "31",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "32",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "33",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>希望通りの配属先になる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "34",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "35",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "36",
                    name: "4月 はじめての給料",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['life', 'stop'], voice: ["p13-1"], btn_label: `次へ`,
                            message: `<p>給料日です！給与明細書には何が書かれているのか確認していきましょう。<br>
						給与明細は大きく「勤怠・支給・控除」の3つに分かれています。</p>
						<img src='${template_directory_url}/assets/img/p13-1-1.png'>
						<p>　</p>
						<p>【勤怠】出勤日数や残業時間</p>
						<img src='${template_directory_url}/assets/img/p13-1-2.png'>
						<p>　</p>
						<p>【支給】給与の内訳と合計</p>
						<img src='${template_directory_url}/assets/img/p13-1-3.png'>
						<p>ここで一番大切なのは、合計額ではなく</p>
						<p style="text-align: center; font-size: 20px;"><strong>基本給/基礎給</strong></p>
						<p>です。ボーナスや退職金の基準になる部分なので、あまりに低い場合は要注意！必ずチェックして下さい。</p>
						<p>　</p>
						<p>【控除】給与から差し引かれる金額</p>
						<img src='${template_directory_url}/assets/img/p13-1-4.png'>
						<p>では、実際の収入はいくらでしょう？一番右側の下、「振込支給額」です。
						ガッツリ減った自分のお金がどこへ行き、なにに使われているのか？ゲームをするうちに少しずつわかってきます。</p>
						<img src='${template_directory_url}/assets/img/p13-1-5.png'>`, kind: EventKind.MsgNormal, value: +1
                        },
                        {
                            evCategory: ['zeikin', 'stop'], voice: ["p13-2"], btn_label: `次へ`, message: `
						<style>.message h2:first-child{display: none;}</style>
						<h2>所得税の支払い開始</h2>
						<p>所得税について。下の<span style="color: #E60012">赤い波線部分</span>を見て下さい。</p>
						<img src='${template_directory_url}/assets/img/p13-2-1.png'>
						<p>所得税は国に納める税金で、給与だけでなく所得があった時には必ず課されます。
						所得が多いほど税率が増える「累進課税」で、公平性が高いと言われています。</p>
						
						<p>2013年から、ここに復興特別所得税が加算されました。東日本大震災の復興財源ですが、所得税と併せて徴収されているため、気づかない人も多いようです。</p>
						
						<p>コロナ禍でも、多くのお金が必要になりました。ただ、財源についてはあまり話し合われていません。
						ほとんどが国債、つまり借金なので、将来の納税者が負担することになります。</p>`, kind: EventKind.MsgNormal, value: 0
                        },
                        {
                            evCategory: ['roudou', 'stop'], voice: ["p13-3"], btn_label: `閉じる`,
                            message: `
						<style>.message h2:first-child{display: none;}</style>
						<h2>雇用保険の支払い開始</h2>
						<p>健康保険や年金は、国民全員で支え合うので「社会保険」と言いますが、労働者のための「労働保険」という制度もあります。</p>

						<p><strong>労働保険は2種類</strong></p>

						<p>【雇用保険】<br>
						失業手当などのために、従業員が加入。<br>
						下の明細書の<span style="color: #E60012">赤い波線部分</span>を見て下さい。</p>
						
						<img src='${template_directory_url}/assets/img/p13-3-1.png'>

						<p>【労災保険】<br>
						従業員が仕事中や通勤途中にケガをしたり病気になったりした時は、労働災害といって医療費はすべて会社が負担します。その時に備えて、会社が入る保険です。</p>
						
						<p>実は労災保険未加入の会社がたくさんあります。法律違反ですが 通常、労働者には分かりません。<br>
						「ウチはなにがあっても、従業員を守りません！」の意思表示。こわいです。働く時には加入の有無を、必ず調べましょう。</p>

						<p style="color: #727171">ライフリテラシーＨＰ「失敗しない企業選び ～「労災保険」を確認しよう～」<a href="https://life-literacy.com/blog/1065.html" target="_blank" style="color: #727171">https://life-literacy.com/blog/1065.html</a>から調べられます。</p>

						<p style="color: #727171"><br>【豆知識】<br>
						保険料は会社と折半。社会保険同様、税金も投入されています。コロナ禍の助成金等で財政が急速に悪化した為、2022年から労使ともに負担増。
						</p>`, kind: EventKind.MsgNormal, value: 0
                        },
                    ],
                },
                {
                    evid: "37",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "38",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "39",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "40",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>ゴールデンウイークが終わり5月病になる<br>２つもどる</p>`, kind: EventKind.MoveNormal, value: -2, chain: false }],
                },
                {
                    evid: "41",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "42",
                    name: "",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        { evCategory: ['common'], message: `<p>保険証の種類でルートが分かれます。<br></p>`, kind: EventKind.MoveNormal, value: +1, chain: false }
                    ],
                    // P15
                },
                {
                    evid: "43a",
                    name: "",
                    direction: Direction.Up,
                    events: [{ kind: EventKind.None }],
                },
                {
                    evid: "44a",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>保険料の支払いを忘れ、延滞金を支払う<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                },
                {
                    evid: "45a",
                    name: "",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "45a_dummy",
                    name: "5月 2回目の給料日<br>健康保険・厚生年金の支払い開始",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['life', 'stop'], voice: ["p16"], message: `<p>会社員の人は健康保険や年金など、社会保険の支払いが開始。
					くわしく見てみましょう。社会保険の使命は、「互いに支え合い、命と生活を守る」こと。</p>
					<p>日本国憲法の有名な条文、「すべて国民は健康で文化的な最低限度の生活を営む権利を有する」、そして「国がその責務を負う」という第25条「生存権」に基づいています。</p>
					<p>‘支え合う’ので、収入の多い人はたくさん払い、少ない人や少ない時期には保険料が安くなります。</p>
					<p>累進料率です。そして必ずしも「多く払ったから多くもらえる」わけではありません。例えば、毎月払う健康保険料は人によってバラバラですが、病院での負担は等しく1割～3割です。</p>
					<p>ただし保険料率は、それぞれの健康保険組合で決められます。このため健康な人が多く医療費が少ない組合は保険料を低くできます。
					つまり健康維持は、自分たちの出費を抑えるうえでも重要なのです。</p>`, kind: EventKind.MsgNormal, value: +1
                        },
                        { message: ``, kind: EventKind.Settlement, value: 0 }
                    ],
                },
            ],
            [//会社員ルート
                {
                    evid: "16b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.Start, value: 0 }],
                },
                {
                    evid: "17b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "18b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "19b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "20b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "21b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "22b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "23b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "24b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "25b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "26b",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "27",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "28",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>敬語がキチンと使えず、へこむ<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                    // P10
                },
                {
                    evid: "29",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "30",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>無事に研修が終わる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "31",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "32",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "33",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>希望通りの配属先になる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "34",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "35",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "36",
                    name: "4月 はじめての給料",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['life', 'stop'], voice: ["p13-1"], btn_label: `次へ`,
                            message: `<p>給料日です！給与明細書には何が書かれているのか確認していきましょう。</p>
						<img src='${template_directory_url}/assets/img/p13-1-1.png'>
						<p>給与明細は大きく「勤怠・支給・控除」の3つに分かれています。左から見ていきましょう。</p>
						<p>【勤怠】出勤日数や残業時間</p><img src='${template_directory_url}/assets/img/p13-1-2.png'>
						<p>【支給】給与の内訳と合計。ここで一番大切なのは、合計額ではなく基本給/基礎給です。ボーナスや退職金の基準になる部分なので、あまりに低い場合は要注意！必ずチェックして下さい。</p>
						<img src='${template_directory_url}/assets/img/p13-1-3.png'>
						<p>【控除】給与から差し引かれる金額</p>
						<img src='${template_directory_url}/assets/img/p13-1-4.png'>
						<p>では、実際の収入はいくらでしょう？一番右側の下、「振込支給額」です。
						ガッツリ減った自分のお金がどこへ行き、なにに使われているのか？ゲームをするうちに少しずつわかってきます。</p>
						<img src='${template_directory_url}/assets/img/p13-1-5.png'>`, kind: EventKind.MsgNormal, value: +1
                        },
                        {
                            evCategory: ['zeikin', 'stop'], voice: ["p13-2"], btn_label: `次へ`,
                            message: `<style>.message h2:first-child{display: none;}</style>
						<h2>所得税の支払い開始</h2>
						<p>所得税について。下の<span style="color: #E60012">赤い波線部分</span>を見て下さい。</p>
						<img src='${template_directory_url}/assets/img/p13-2-1.png'>
						<p>所得税は国に納める税金で、給与だけでなく所得があった時には必ず課されます。
						所得が多いほど税率が増える「累進課税」で、公平性が高いと言われています。</p>
						
						<p>2013年から、ここに復興特別所得税が加算されました。東日本大震災の復興財源ですが、所得税と併せて徴収されているため、気づかない人も多いようです。</p>
						
						<p>コロナ禍でも、多くのお金が必要になりました。ただ、財源についてはあまり話し合われていません。
						ほとんどが国債、つまり借金なので、将来の納税者が負担することになります。</p>`, kind: EventKind.MsgNormal, value: 0
                        },
                        {
                            evCategory: ['roudou', 'stop'], voice: ["p13-3"], btn_label: `閉じる`,
                            message: `
						<style>.message h2:first-child{display: none;}</style>
						<h2>雇用保険の支払い開始</h2>
						<p>健康保険や年金は、国民全員で支え合うので「社会保険」と言いますが、労働者のための「労働保険」という制度もあります。</p>

						<p><strong>労働保険は2種類</strong></p>

						<p>【雇用保険】<br>
						失業手当などのために、従業員が加入。<br>
						下の明細書の<span style="color: #E60012">赤い波線部分</span>を見て下さい。</p>
						
						<img src='${template_directory_url}/assets/img/p13-3-1.png'>

						<p>【労災保険】<br>
						従業員が仕事中や通勤途中にケガをしたり病気になったりした時は、労働災害といって医療費はすべて会社が負担します。その時に備えて、会社が入る保険です。</p>
						
						<p>実は労災保険未加入の会社がたくさんあります。法律違反ですが 通常、労働者には分かりません。<br>
						「ウチはなにがあっても、従業員を守りません！」の意思表示。こわいです。働く時には加入の有無を、必ず調べましょう。</p>

						<p style="color: #727171">ライフリテラシーＨＰ「ブラック企業 超簡単な見わけ方」<a href="https://life-literacy.com/blog/1065.html" target="_blank" style="color: #727171">https://life-literacy.com/blog/1065.html</a>から調べられます。</p>

						<p style="color: #727171"><br>【豆知識】<br>
						保険料は会社と折半。社会保険同様、税金も投入されています。コロナ禍の助成金等で財政が急速に悪化した為、2022年から労使ともに負担増。
						</p>`, kind: EventKind.MsgNormal, value: 0
                        },
                    ],
                    // P13
                },
                {
                    evid: "37",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "38",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "39",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "40",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>ゴールデンウイークが終わり5月病になる<br>２つもどる</p>`, kind: EventKind.MoveNormal, value: -2, chain: false }],
                },
                {
                    evid: "41",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "42",
                    name: "",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        { evCategory: ['common'], message: `<p>保険証の種類でルートが分かれます。<br></p>`, kind: EventKind.MoveNormal, value: +1, chain: false }
                    ],
                    // P15
                },
                {
                    evid: "43b",
                    name: "",
                    direction: Direction.Right,
                    events: [{ kind: EventKind.None }],
                },
                {
                    evid: "44b",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "45b",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "45b_dummy",
                    name: "5月 2回目の給料日<br>健康保険・厚生年金の支払い開始",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['life', 'stop'], voice: ["p16"], message: `<p>会社員の人は健康保険や年金など、社会保険の支払いが開始。
					くわしく見てみましょう。社会保険の使命は、「互いに支え合い、命と生活を守る」こと。</p>
					<p>日本国憲法の有名な条文、「すべて国民は健康で文化的な最低限度の生活を営む権利を有する」、そして「国がその責務を負う」という第25条「生存権」に基づいています。</p>
					<p>‘支え合う’ので、収入の多い人はたくさん払い、少ない人や少ない時期には保険料が安くなります。</p>
					<p>累進料率です。そして必ずしも「多く払ったから多くもらえる」わけではありません。例えば、毎月払う健康保険料は人によってバラバラですが、病院での負担は等しく1割～3割です。</p>
					<p>ただし保険料率は、それぞれの健康保険組合で決められます。このため、健康な人が多く医療費が少ない組合は保険料を低くできます。
					つまり健康維持は、自分たちの出費を抑えるうえでも重要なのです。</p>`, kind: EventKind.MsgNormal, value: +1
                        },
                        { message: ``, kind: EventKind.Settlement, value: 0 }
                    ],
                },
            ]
        ],
        [
            [
                {
                    evid: "46",
                    name: "",
                    direction: Direction.None,
                    events: [{ kind: EventKind.Start, value: 0 }],
                    // P16
                },
                {
                    evid: "47",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "48",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>お盆休みエンジョイ<br>１つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "49",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "50",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "51",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], message: `<p>学生時代の長い夏休みを思い出しブルーになる<br>2つもどる</p>`, kind: EventKind.MoveNormal, value: -2, chain: false }],
                },
                {
                    evid: "52",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "53",
                    name: "パワハラでうつ病に！ 病院へ<br>労災申請",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['roudou', 'stop'], voice: ["p20"], message: `<p>従業員を大切にする会社もたくさんありますが、残念ながら、そうでない会社もあります。
					問題が起きた時、その事実を自分で証明しなければならない、という場合があるのです。</p>
					<p>特にアルバイト、非正規などの人は不安定で弱い立場です。疑問を持ったり、危険を感じたりしても辞められない時には、ひとつひとつのことを記録に残すようにして下さい。大切な証拠になります。</p>
					<p>日本では労働者を守る様々な法律があります。これは勤労について明記されている憲法第27条、28条が根拠となっています。
					過去の労働者たちによってようやく勝ち取られたこれらの決まりによって、今、雇用形態にかかわらず、すべての労働者が守られているのです。</p>
					<p><span><br>【豆知識】<br>アルバイトをしている人などが特に知っておきたいのは「最低賃金法」。使用者は国が定める最低賃金以上を払わなければならない。
					金額は地域別に異なるので、自分の時給は法律が守られているか調べてみよう。</span></p>`, kind: EventKind.MsgNormal, value: +1
                        }],
                    // P20
                },
                {
                    evid: "54",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "55",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>忘年会の幹事をそつなくこなす<br>1つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "56",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "57",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>入社2年目に早速新入社員に<br>先輩風を吹かして嫌われる<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                },
                {
                    evid: "58",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "59",
                    name: "入社2年目 6月～<br>住民税の支払い開始",
                    direction: Direction.Right,
                    events: [
                        { message: ``, kind: EventKind.Start, value: 0 },
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['zeikin', 'stop'], voice: ["p23"], message: `<p>住民税。給与明細書の<span style="color: #E60012">赤い波線</span>を見て下さい。</p>
					<img src='${template_directory_url}/assets/img/p23-1.png'>
					<p>住民税は住んでいる市区町村と都道府県に納めます。地方自治のために使われ、例えば道路整備や公共の建物、そこで働く職員や先生の給料の一部になります。</p>
					<p>住民税での一番の注意は「前年度の収入に課税される」、つまり1年後のあと払いという点です。退職した翌年には、たとえ無収入でも納めなければならないので気をつけて下さい。</p>
					<p>給与からは2種類の税金を払いますね。仕組みがだいぶ違うのでまとめてみましょう。</p>
					<p><strong>【所得税】は、国税。収入を得た年に納める<br>【住民税】は、地方税。次の年に納める</strong></p>
					<p>また住民税も東日本大震災のあと、地域での災害に備えるため増額されています。</p>
					<p><span>ゲームでも2年目6月から納めています。これは入社年の所得に対して課された税金。なぜこんなに遅い時期になるのでしょう？
					住民税は（年末調整や確定申告によって）確定した前年の所得税をもとに計算するため、時間がかかるのです。<span></p>`, kind: EventKind.MsgNormal, value: +1
                        }],
                    // P23
                },
                {
                    evid: "60",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "61",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>7月 初めてのボーナス！<br>会社員の人のみ ２つ進む</p>`, kind: EventKind.Ev61, value: +2, chain: false }],
                },
                {
                    evid: "62",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "63",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "64",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "65",
                    name: "",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        { evCategory: ['common'], message: `<p>健康診断を受け、胃に潰瘍が見つかりショック！<br>すぐに病院へ向かう。<br>2つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: true }
                    ],
                },
                {
                    evid: "66",
                    name: "",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "67",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `手術する`, kind: EventKind.MsgNormal, value: 0 }],
                },
                {
                    evid: "68",
                    name: "",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "69",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>友人がお見舞いに来てくれる<br>3つ進む</p>`, kind: EventKind.MoveNormal, value: +3, chain: true }],
                },
                {
                    evid: "70",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>入院費がかさむ<br>1回休み</p>`, kind: EventKind.MsgNormal, value: -1, rest: 1 }],
                },
                {
                    evid: "71",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>入院が長引く<br>3つもどる</p>`, kind: EventKind.MoveNormal, value: -3, chain: false }],
                },
                {
                    evid: "72",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `退院`, kind: EventKind.MsgNormal, value: 0 }],
                },
                {
                    evid: "73",
                    name: "医療費が高額に！<br>高額療養申請をする",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['kenpo', 'stop', 'quiz_content'], voice: ["p32", "p32", "p32", "p32"], kind: EventKind.Quiz, value: 0,
                            message: `
						<h2 class="challenge_heading1" style="background: #e60012;">クイズに挑戦！</h2>
						<p>病気やケガをした時に心配なのは、身体のことだけではありません。お金のことも心配です。
						金銭的、精神的な負担を少しでも減らすため、日本にはいろいろな制度があります。</p>
						<p>代表的なのが「高額療養費制度」。自分で支払う医療費には限度があり、高額だった時には限度額を超えた分を健康保険組合に負担してもらう、という制度です。下の図を見て下さい。高額療養費制度をぜひ利用しましょう。</p>
						<img src='${template_directory_url}/assets/img/p32-q1-1.png'>

						<div class="quiz_item">
							<h2 class="challenge_heading1">問題</h2>
							<p>では問題です。「高額療養費制度」の限度額は、収入によって変わるのですが、あなたの月給が30万円だった時、ひと月の限度額は、いくらになるでしょう？</p>
						</div>
						<div class="quiz_item">
							<h2 class="challenge_heading1">問題</h2>
							<p>では問題です。「高額療養費制度」は、申請するとあとからお金が戻ってくる、という制度です。つまり退院する時には、立て替えなければなりません。かなり大変です。これを避けるための方法があるのですが、いったいどちらでしょうか？</p>
						</div>
						</div>
						<div class="quiz_item">
							<h2 class="challenge_heading1">問題</h2>
							<p>では問題です。長期間仕事を休むため収入も減ります。治療費などで出費が増えるのに大変な事態です。会社には、給与をもらえる休日「有給休暇」が法律で義務付けられていて、理由を問わず使用できるのです。休暇日数は在職期間などによって異なりますが、有給休暇を使えるのはだれでしょうか？</p>
						</div>
						<div class="quiz_item">
							<h2 class="challenge_heading1">問題</h2>
							<p>では問題です。「高額療養費制度」のほかに「有給休暇」も利用できます。<br>
							ケガや病気の時にも使える「有給休暇」は入社半年後に付与される制度です。でも、もしも半年経たない間にケガや病気で長期間仕事を休まなければならなかったら、どうすればよいでしょう。治療費と給与減額のダブルパンチを避けるために、あなたはどちらの手続きを行いますか？</p>
						</div>
						`,
                            quiz_choices: [
                                ["① 3万円", "② 8万円", "③ 15万円"],
                                ["① 入院中、健康保険組合に<br>　 「限度額適用認定証」を申請する", "② 退院時、病院に<br>　 「限度額適用認定証」を申請する"],
                                ["① 正社員", "② パート・アルバイトなど非正規従業員", "③ 正社員と非正規、両方"],
                                ["① 会社に「有給休暇前借り届」を申請する", "② 健康保険組合に「傷病手当金」を申請する"],
                            ],
                            quiz_answer: `
							<h2 class="challenge_heading1">答え</h2>
							<div class="quiz_item">
								<p style="text-align: center;"><strong>② 8万円</strong></p>
								<p style="text-align: center;"><strong>解説</strong></p>
								<p>ただし申請しなければカバーされません。また一旦は全額払わなければならず、大変です。このため事前に限度額適用認定制度を利用するか、マイナンバーカードを保険証にしておくと立替えの必要もなくなります。</p>
							</div>
							<div class="quiz_item">
								<p style="text-align: center;"><strong>① 入院中、健康保険組合に<br>「限度額適用認定証」を申請する。</strong></p>
								<p style="text-align: center;"><strong>解説</strong></p>
								<p>退院する時に、この書類と保険証を病院の窓口に提示すると、支払いが自己負担限度額までになります。退院する時には必要なので医療費が高額になると分かった時には、入院中に用意しましょう。</p>
							</div>
							<div class="quiz_item">
								<p style="text-align: center;"><strong>③ 正社員と非正規両方</strong></p>
								<p style="text-align: center;"><strong>解説</strong></p>
								<p>正社員だけでなく、アルバイトでも半年経つともらえます（労働基準法第39条）。例えば、週２日勤務の場合、最初の年は年間３日です。アルバイトをしている人は、自分の給与明細書を確認してみましょう。</p>
							</div>
							<div class="quiz_item">
								<p style="text-align: center;"><strong>② 「傷病手当金」を申請する。</strong></p>
								<p style="text-align: center;"><strong>解説</strong></p>
								<p>有給休暇の前借りという制度はありません。「傷病手当金」は、ケガや病気で会社を休み、充分な報酬が得られない時に申請できます。しかし国民健康保険（フリーターや自営業者が加入する）にはないので、覚えておきましょう。</p>
							</div>
							<p style="text-align: center;"><strong>正解の場合は1つ進む</strong></p>`,
                        },
                    ]	// P32：クイズ
                },
                {
                    evid: "74",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "75",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], message: `大きなプロジェクトメンバーに抜擢！<br>２つ進む`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "76",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "77",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "78",
                    name: "転機<br>会社が倒産の危機！",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['kenpo', 'kenpo_black', 'stop'], voice: ["p34"], btn_label: `サイコロを振る`,
                            message: `
						<h2 class="tousan_heading">サイコロを振り、出た目によって会社が倒産してしまう</h2>
						<img src='${template_directory_url}/assets/img/p34-1.png'>
						<img src='${template_directory_url}/assets/img/p34-2.png'>`, kind: EventKind.P34, value: +1
                        }],
                    //P34
                },
                {
                    evid: "79",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "80",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "81",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "82",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "83",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "84",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "85",
                    name: "",
                    direction: Direction.Right,
                    events: [
                        { kind: EventKind.ForceStopP35, value: 0 },
                        {
                            evCategory: ['kenpo', 'stop_freeter'], voice: ["p35"], btn_label: `サイコロを振る`, message: `
						<h2 class="challenge_heading1">チャレンジ</h2>
						<p>収入が減り、年金が払えない！どうしよう・・・</p>
						<h2 class="challenge_heading2">サイコロを振り、出た目によって年金滞納？！</h2>
						<img src='${template_directory_url}/assets/img/p35-1.png'>`, kind: EventKind.P35, value: +1
                        }],
                    // P35：フリーターか自営業のみ
                },
                {
                    evid: "86",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "87",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "88",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], message: `突然の内閣総辞職<br>政治ニュースにくぎづけ<br>１つ進む`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "89",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "90",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `衆院選！各政党の政策を読みくらべる<br>２つ進む`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "91",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "92",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "93",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], message: `衆院選！投票に行く<br>５つ進む`, kind: EventKind.MoveNormal, value: +5, chain: false }],
                },
                {
                    evid: "94",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "95",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "96",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `衆院選！私一人が投票しても何も変わらないでしょ！？<br>投票を棄権<br>５つもどる`, kind: EventKind.MoveNormal, value: -5, chain: false }],
                },
                {
                    evid: "97",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "98",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "99",
                    name: "仕事中にけがをした",
                    direction: Direction.Down,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['kenpo', 'kenpo_black', 'stop'], voice: ["p40"], btn_label: `サイコロを振る`, message: `
						<h2 class="challenge_heading1">チャレンジ</h2>
						<h2 class="challenge_heading2">サイコロを振り、出た目によって労災が適用される</h2>
						<img src='${template_directory_url}/assets/img/p40-1.png'>
						<p>【労災保険】は従業員が仕事中や通勤途中にケガや病気になった時は、労働災害といって医療費はすべて会社が負担します。<br>
						その時に備えて、会社が入る保険。</p>`, kind: EventKind.P40, value: 0
                        }],
                    // P40
                },
                {
                    evid: "100",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "101",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], voice: ["p40"], message: `<p>通勤途中にひとめぼれ<br>毎朝幸せで元気になる<br>1つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                    // P41
                },
                {
                    evid: "102",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "103",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "104",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>残業続きで体調不良<br>1つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                    // P42
                },
                {
                    evid: "105",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "106",
                    name: "病気に！<br>身体に障害が残ってしまう",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['nenkin', 'stop'], voice: ["p43"], message: `
					<p>年金は高齢になった時のため、と思われていますがそれだけではありません。</p>
					<p>正式名称は…「年金保険」。保険は、先々の不安に “備える” ものですよね。</p>
					<p>ですから不測の事態、例えば身体に障害を負って働けなくなった時などには一定のお金が支給されます。</p>
					<p>一生涯、所得補償が続くこの「障害年金」の存在は、若い人にこそ重要でしょう。</p>
					<p>ただし保険ですから、保険料が未納の人はもらえません。
					払えない場合は、必ず市区町村役場などに相談して下さい。減額や待ってもらう手続きができます。
					20歳以上の学生で納付できない場合は、必ず「学生納付特例制度」を利用しましょう。</p>
					<p>また年金手帳は2022年に廃止されました。基礎年金番号通知書、マイナンバーカードなどで手続きができます。</p>
                    <p><span>【豆知識1】<br>年金には老齢年金、障害年金、亡くなった方の遺族を支える遺族年金があり、 ひとつの保険料で３つの安心が得られる仕組みになっています。</span></p>
                    <p><span>【豆知識2】<br>「国民年金保険料学生納付特例制度」は保険料を納付しなくても、納付済みと同じ待遇になる優遇制度。
					申請しているかどうかで万が一の時には、一生で6千万円の差が出るとも言われています。</span></p>
					<hr>
					<p><strong>●年金を滞納しているプレイヤーは2回休み<br>
					●それ以外のプレイヤーは1つ進む</strong></p>`, kind: EventKind.P42, value: 0
                        }],
                },
                {
                    evid: "107",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "108",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], voice: ["p43"], message: `<p>仕事が認められ栄転<br>（今までよりも高い地位で転勤すること）<br>３つ進む</p>`, kind: EventKind.MoveNormal, value: +3, chain: false }],
                },
                {
                    evid: "109",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "110",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `自分磨き、オンラインヨガを始める<br>1つ進む`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "111",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "112",
                    name: "",
                    direction: Direction.Right,
                    events: [
                        {
                            evCategory: ['common', 'stop'], message: `
						同期が先に昇進してがっかり！<br>
						あとのプレイヤーが1人通過するまで待つ<br>
						（最後の場合は待たなくてよい）`, kind: EventKind.Wait, value: -1
                        }
                    ],
                },
                {
                    evid: "113",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "114",
                    name: "",
                    direction: Direction.Down,
                    events: [{ evCategory: ['common'], message: `シックスパックを目指し、カラダを鍛え始める<br>2つ進む`, kind: EventKind.MoveNormal, value: +2, chain: true }],
                },
                {
                    evid: "115",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "116",
                    name: "WHO（世界保健機関）が2000年、<br>日本の医療保険制度を世界一と認定",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['kenpo', 'stop_first'], voice: ["p48"], message: `<p>日本の医療保険は大変優れ、世界でも高い評価を受けてきました。
					しかし現在、この制度が危機的な状況であることは、あまり知られていません。
					高齢化や医療の高度化などで医療費が増大し、解散する健保が相次いでいるのです。</p>
					<p>コロナ禍でも保健所や一部の病院に負担が集中する、患者が医療機関にアクセスできないなど、様々な問題が浮き彫りになりました。</p>
					<p>「診察や薬は3割負担」「病院を選べる」は、あたり前ではありません。</p>
                    <p>みんなで問題を共有し、制度について真剣に考えていく必要があるのです。</span></p>
                    <p><span>【豆知識1】<br>解散後は国保か（中小企業が加入する）協会けんぽが受け皿になります。</span></p>
                    <p><span>【豆知識2】<br>例えば公的医療保険が貧弱な米国では、高額な保険料を払い民間の医療保険に加入する。
					医療費も超高額。2020年コロナ禍 初期には、診察代を心配して病院に行かない人が続出し、急激な感染拡大の一因になったと云われています。</span></p>
                    <p><span>【豆知識3】<br>薬価も財政を圧迫。高額化が進み1億円を超える治療薬も登場しています。
					効果の低い薬が使われ続けているケースも多く、 費用対効果を取り入れた制度が開始されましたがまだ一部に留まっています。
					医療制度改革として現在検討が進んでいるのは「かかりつけ医制度」「オンライン診療」など。
					雇用や税収を増やす効果的な医療費の使い方、方法を考える業界横断的な「新しい予防医療教育」にも注目が集まっています。</span></p>`, kind: EventKind.MsgNormal, value: 0
                        }
                    ],
                },
                {
                    evid: "117",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "118",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], voice: ["p48"], message: `<p>恋人ができる<br>１つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                    // P48
                },
                {
                    evid: "119",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "120",
                    name: "リテラシーメモ",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['life', 'stop_first'], voice: ["p50-life-literacy"], message: `
					<p>ここまで見てきたように、日本にはたくさんの制度や手当があります。しかし一番知っておく必要があるのは「申請主義」という点です。
					例え該当していてもほとんどの場合教えてもらえない、ということです。利用するには…</p>
					<p style="text-align: center;"><strong>自分で知っておくこと、<br>そして必ず申請すること</strong></p>
					<p>が、不可欠。地域の広報誌などは毎月チェックし、リテラシーを身につけていきましょう。</p>
					<p>申請主義はほかの問題に関しても同じかもしれません。
					どうにもならないという事態に陥った時、最後に自分を守ってくれるのは「助けてほしい」と言える勇気なのです。</p>
					<p>一方、財政を見届けることも大事です。巨額の税金が毎年ムダづかいと指摘されますが、なぜ減らせないのでしょう。
					私たちが払ったお金を、より正しく使うための方法も考えなければなりません。
					例えば、最近注目されているのは「政府から独立した財政機関」の存在です。
					長期的な財政規律を維持しやすいため、すでに多くの先進国で設置されています。</p>`, kind: EventKind.MsgNormal, value: +1
                        }
                    ],
                    // P50
                },
                {
                    evid: "121",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>係長に昇進<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                    // P51：2つ進む
                },
                {
                    evid: "122",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "123",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "124",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>祝！結婚<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "125",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "126",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "127",
                    name: "住宅を購入する",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['zeikin', 'stop_first'], voice: ["p53-tax"], message: `
					<p>家を買う場合、多くの人は借金(ローン)を背負います。そのため特別な減税措置があります。しかし減税の金額や期間は、購入年度によって違うのです。なぜなのでしょうか？</p>
					<p>住宅を造るには、設備や材料、人手が必要です。さらに、新しく家を買った人は電化製品や家具など、物を買う場合が多いものです。つまり…</p>
					<img src='${template_directory_url}/assets/img/p53-1.png' style="width: 220px; display: block; margin: 40px auto;">
					<p>このため不景気の時には減税の額や期間を増やし、家を買いやすくすることが景気対策として行われてきました。<br>
					しかし税金が安くなっても、多額の借金を背負うことに変わりありません。また、新築住宅の奨励や少子化などにより、空家が大きな社会問題になっています。<br>
					情報を鵜吞みにするのではなく、違う視点から見るよう心がけ、時には時代を眺めるような広い視野を持つことも大切です。</p>`, kind: EventKind.MsgNormal, value: +1
                        }
                    ],
                    //P53
                },
                {
                    evid: "128",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "129",
                    name: "ゴール",
                    direction: Direction.Down,
                    events: [
                        {
                            evCategory: ['life', 'stop_first'], voice: ["p0-3"], message: `
					<p>下の図を見て下さい。</p>
					<img src='${template_directory_url}/assets/img/goal.png'>
					<p>制度や税、経済や社会問題など、一見無関係と思われていることも実は互いに関係しています。
					最終的にはすべて、大きな決定権をにぎる「政治」につながっていると言えるでしょう。
					そして政治を行う代表は「投票」で「私たち」が選びます。自分の1票を大事にして下さい。</p>

					<p>日本だけでなく、多くの国で、近年投票率低下が問題になっています。
					このためより正しくより速く民意を反映する仕組みや、スマホを使用し市民が政策決定する地域などがでてきました。<br>
					今、世界各地で「新しい民主主義の形」を模索する取り組みが始まっています。</p>
					
					<p>決まったことに一方的に従うのではなく、社会を支え、その方向性を決めるひとりとして考え、行動する。
					<span style="color: #E50012;">自分のことも社会のことも人任せにしない。</span>
					この姿勢こそが、我が身を守る最大の「ライフ・リテラシー」なのです。</p>`, kind: EventKind.Goal
                        }],
                },
            ]
        ]
    ],
};


export const course_demo: Course = {
    Main: [
        [
            [
                {
                    evid: "1",
                    name: "",
                    direction: Direction.None,
                    events: [{ evCategory: ['common'], message: ``, kind: EventKind.Start }],
                },
                {
                    evid: "2",
                    name: "",
                    direction: Direction.Right,
                    events: [{ evCategory: ['common'], message: `<p>さっそく遅刻する<br>スタートに戻る</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                },
                {
                    evid: "3",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "4",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>お客さんに恋をする<br>1つ進む</p>`, kind: EventKind.MoveNormal, value: +1, chain: false }],
                },
                {
                    evid: "5",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "6",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>バイトテロ<br>いたずら画像をSNSに投稿<br>お店に損害を与えクビになる<br>1回休み</p>`, kind: EventKind.MsgNormal, value: -1, rest: 1 }],
                },
                {
                    evid: "7",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "8",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>初めてのバイト料で親にプレゼントを買う<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "9",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "10",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "11",
                    name: "バイトの残業代が<br>支払われていないことに気づく!",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStopOnce, value: 0 },
                        {
                            evCategory: ['roudou', 'stop_first'], voice: ["p6"], message: `<p>残念ながら、違法な働きかたを強要する職場もあります。おかしいと感じたらひとりで悩まず、勇気を出して相談しましょう。<br>
						検索ワードは...</p><p><strong>「○○県 総合労働相談 」「法テラス 」、</strong>など。</p>
						<p>必ず公的機関を選んで下さい。税金で運営されているため無料です。学生でも消費税を払っている立派な納税者。相談料を前払いしたと考えて、どんどん活用しましょう。放置しても解決はしません。早めに相談する姿勢を徹底して下さい。</p>
						<p>また給与明細書は法的な証拠にもなる重要な書類。きちんと内容を確認し、大切に保管しましょう。給与明細をもらっていない場合はすぐに辞めて下さい。最低限の法律を守っていない会社です(所得税法違反)。</p>
						`, kind: EventKind.MsgNormal, value: -1
                        }
                    ],
                },
                {
                    evid: "12",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "13",
                    name: "",
                    direction: Direction.Right,
                    events: [{
                        evCategory: ['common'], message: `<p>学校を卒業する<br>
					2つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: true
                    }],
                },
                {
                    evid: "14",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "15",
                    name: "",
                    direction: Direction.Up,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        {
                            evCategory: ['nenkin', 'stop'], voice: ["p8-2"], btn_label: `サイコロを振る`,
                            message: ``, kind: EventKind.P8, value: 0, chain: false
                        },
                    ],
                },
            ],
        ],
        [
            [//フリーター・自営業ルート
                {
                    evid: "16",
                    name: "",
                    direction: Direction.Right,
                    events: [{ kind: EventKind.None }],
                },
                {
                    evid: "17",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "18",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "19",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "20",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "21",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "22",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "23",
                    name: "市役所で手続き",
                    direction: Direction.Left,
                    events: [
                        { kind: EventKind.ForceStop, value: 0 },
                        { evCategory: ['common', 'stop'], message: `<p>国保保険証をもらい、年金の加入状況も確認</p>`, kind: EventKind.P9, value: +1 }
                    ],
                    // P9
                },
                {
                    evid: "24",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "25",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "26",
                    name: "なし",
                    direction: Direction.Down,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "27",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "28",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>敬語がキチンと使えず、へこむ<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                    // P10
                },
                {
                    evid: "29",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "30",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>無事に研修が終わる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "31",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "32",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "33",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>希望通りの配属先になる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "34",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "35",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "36",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "37",
                    name: "ゲーム終了",
                    direction: Direction.None,
                    events: [
                        {
                            evCategory: ['common', 'stop'], message: `
					<p>デモ版はここまでです。</p>`, kind: EventKind.Goal
                        }],
                },
            ],
            [//会社員ルート
                {
                    evid: "16b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.Start, value: 0 }],
                },
                {
                    evid: "17b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "18b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "19b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "20b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "21b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "22b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "23b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "24b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "25b",
                    name: "なし",
                    direction: Direction.None,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "26b",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "27",
                    name: "なし",
                    direction: Direction.Left,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "28",
                    name: "",
                    direction: Direction.Left,
                    events: [{ evCategory: ['common'], message: `<p>敬語がキチンと使えず、へこむ<br>１つもどる</p>`, kind: EventKind.MoveNormal, value: -1, chain: false }],
                    // P10
                },
                {
                    evid: "29",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "30",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>無事に研修が終わる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "31",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "32",
                    name: "なし",
                    direction: Direction.Up,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "33",
                    name: "",
                    direction: Direction.Up,
                    events: [{ evCategory: ['common'], message: `<p>希望通りの配属先になる<br>２つ進む</p>`, kind: EventKind.MoveNormal, value: +2, chain: false }],
                },
                {
                    evid: "34",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "35",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "36",
                    name: "なし",
                    direction: Direction.Right,
                    events: [{ message: ``, kind: EventKind.None, value: 0 }],
                },
                {
                    evid: "37",
                    name: "ゲーム終了",
                    direction: Direction.None,
                    events: [
                        {
                            evCategory: ['common', 'stop'], message: `
					<p>デモ版はここまでです。</p>`, kind: EventKind.Goal
                        }],
                },
            ]
        ],
    ],
};

export const course = isDemo ? course_demo : course_main;
