import { template_directory_url } from "../constant";
import { repo } from "../repo";
import { div } from "../util";

export class Status {
	readonly area: HTMLElement;

	constructor(area: HTMLElement) {
		this.area = area;
	}

	updateStatus() {
		//メッセージ表示中は更新しない
		if (repo.status_lock) {
			return false;
		}

		this.area.innerHTML = "";

		repo.players.forEach((player, index) => {
			//console.log("PL" + index + ":" + player.RestCount + "回");
			this.area.appendChild(div`
                <span class="name${repo.turn != 0 && repo.move == index ? " active " : ""} ${repo.turn != 0 && repo.move == index ? "now" : ""}">
				${player.isGoaled ? "[Goal]" : ""}
				${player.name}
				</span>
                <span class="player_img">
					<img src="${template_directory_url}/assets/img/pl_${index + 1}-${player.chara}.webp"></img>
					<span class="rest_count" data-rc="${player.RestCount > 3 ? 0 : player.RestCount}"></span>
				</span>
                <span class="player_labels">
					<label class="kokuho" data-flag="${player.isKokuho}"></label>
					<label class="kenpo" data-flag="${player.isKenpo}"></label>
					<label class="nenkin" data-flag="${player.isNenkin}"></label>
					<label class="meisai" data-flag="${player.isMeisai}"></label>
					<label class="tainou" data-flag="${player.isTainou}"></label>
				</span>
                <span class="player_job" data-job="${player.job}"></span>
            `).className = `player_info`;
		});
	}
}
