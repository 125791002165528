import { sleep } from "../util";

export class Dice {
    /** DOMの領域 */
    readonly area: HTMLElement;
    /** 最小値 */
    readonly min: number;
    /** 最大値 */
    readonly max: number;
    /** ロック */
    private _lock: boolean = false;

    /** ロック状況 */
    get is_lock() { return this._lock; }

    /**
     * サイコロの作成
     * @param area DOMの領域
     * @param min 最小値
     * @param max 最大値
     */
    constructor(area: HTMLElement, min: number, max: number) {
        this.area = area;
        this.min = min;
        this.max = max;
    }

    private set value(value: number) {
        this.area.classList.value = "";
        this.area.classList.add(`dice`);
        this.area.classList.add(`dice-${value}`);
    }

    /**
     * サイコロを振る。
     * @returns min〜maxのランダムな値
     */
    roll(): number {
        const result = Math.floor(Math.random() * (this.max - this.min + 1) + this.min);
        this.value = result;
        return result;
    }

    async rollAnimation(remaining: number = 20): Promise<number> {
        const result = this.roll();
        if (remaining <= 0) return result;
        await sleep(100 - remaining * 3);
        return this.rollAnimation(--remaining);
    }

    lock() {
        this._lock = true;
    }

    unlock() {
        this._lock = false;
    }
}
