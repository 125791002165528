export function parsePixel(str: string): number {
    try {
        if (str.endsWith("px")) {
            return parseInt(str.slice(0, -2));
        } else {
            return parseInt(str);
        }
    } catch (error: any) {
        throw new Error(error);
    }
}

export async function sleep(ms: number): Promise<void> {
    return new Promise((res: Function) => setTimeout(res, ms));
}

export function div(lit: TemplateStringsArray, ...placeholders: any[]): HTMLElement {
    const div = document.createElement("div");
    div.innerHTML = lit.reduce((res, str, index) => res + placeholders[index - 1] + str);
    return div;
};
