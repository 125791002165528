import { home_url } from "./constant";

export async function joinGame(room: number): Promise<any | undefined> {
    const params = new URLSearchParams();
    params.append('action', 'join_game');
    params.append('room', `${room}`);
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    const json = await result.json();
    if (json["status"] == 200) {
        return json["data"];
    } else {
        return undefined;
    }
}

export async function getPlayers(): Promise<any[]> {
    const params = new URLSearchParams();
    params.append('action', 'get_players');
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    const json = await result.json();
    if (json["status"] == 200) {
        return json["data"];
    } else {
        return [];
    }
}

export async function isReady(): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('action', 'is_ready');
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    const json = await result.json();
    if (json["status"] == 200) {
        return json["data"];
    } else {
        return false;
    }
}

export async function GameStart(): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('action', 'game_start');
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    const json = await result.json();
    console.log(json);
    if (json["status"] == 200) {
        return true;
    } else {
        return false;
    }
}

export async function postGoal(): Promise<any> {
    const params = new URLSearchParams();
    params.append('action', 'post_goal');
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    return result;
}

export async function postOption(option: [string, number]): Promise<any> {
    const [name, chara] = option;
    const params = new URLSearchParams();
    params.append('action', 'post_option');
    params.append('name', name);
    params.append('chara', `${chara}`);
    const result = await fetch(`${home_url}/wp-admin/admin-ajax.php`, { method: "POST", body: params });
    return result;
}
